import withResponseHandler from "../../components/ResponseHandler";
import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import {
    AppBar,
    Button,
    Divider,
    FormControl, Grid,
    InputLabel,
    Modal,
    Paper,
    TextField, Toolbar,
    Typography,
    IconButton,
    Select,
    MenuItem
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import ABTable from "../../components/ABTable";
import {
    ExperimentEditAccessHeaders,
    OverriddenUsersTableHeaders
} from "../../constants/constants";
import axios from "axios";
import withLoader from "../../components/Loader";
import {
    validateCohort,
    validateExperimentDescription,
    validateExperimentName,
    validateUserId,
} from "../validators/ABExperimentationValidators";
import { getUserEmail } from "../../utils/tokenUtils";
import ReactJson from "react-json-view";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

const useStyles = (theme) => ({
    editExperimentMetadataBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        width: '75%',
        backgroundColor: '#383838',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    modalTitle: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#8FC9F9'
    },
    textField: {
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 150,
    },
    overriddenUsersTable: {
        padding: theme.spacing(2),
    },
    editAccessTable: {
        padding: theme.spacing(2),
    },
    submitButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    blobPaper: {
        padding: theme.spacing(2),
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
})

const EditExperimentMetadata = (props) => {

    // hooks
    const [experimentName, setExperimentName] = useState("")
    const [experimentNameError, setExperimentNameError] = useState(false)
    const [experimentNameHelperText, setExperimentNameHelperText] = useState("")

    const [experimentDescription, setExperimentDescription] = useState("")
    const [experimentDescriptionError, setExperimentDescriptionError] = useState(false)
    const [experimentDescriptionHelperText, setExperimentDescriptionHelperText] = useState("")

    const [experimentHypothesis, setExperimentHypothesis] = useState("")
    const [primaryMetrics, setPrimaryMetrics] = useState("")
    const [secondaryMetrics, setSecondaryMetrics] = useState("")
    const [gaurdrailMetrics, setGaurdrailMetrics] = useState("")

    const [experimentState, setExperimentState] = useState(0)
    const [overriddenUsers, setOverriddenUsers] = useState({})
    const [experimentId, setExperimentId] = useState("")
    const [editAccess, setEditAccess] = useState([])

    useEffect(() => {
        setExperimentName(props.experimentMetadata?.expName);
        setExperimentDescription(props.experimentMetadata?.expDescription)
        setExperimentHypothesis(props.experimentMetadata?.expHypothesis)
        setPrimaryMetrics(props.experimentMetadata?.primaryMetrics)
        setSecondaryMetrics(props.experimentMetadata?.secondaryMetrics)
        setGaurdrailMetrics(props.experimentMetadata?.gaurdrailMetrics)

        if (typeof props.experimentMetadata?.state === 'string') {
            setExperimentState(getStateIntFromValue(props.experimentMetadata?.state));
        } else {
            setExperimentState(props.experimentMetadata?.state);
        }

        setOverriddenUsers(props.experimentMetadata?.overriddenUsers)
        setExperimentId(props.experimentMetadata?.expId)
        setEditAccess(props.experimentMetadata?.editAccess)
    }, [props.experimentMetadata])

    // event handlers

    const handleModalClose = () => {
        props.editExperimentMetadataModalClose()
    }

    const handleExperimentStateChange = (event) => {
        setExperimentState(parseInt(event.target.value))
    }

    const handleExperimentDescriptionChange = (event) => {

        // validate experiment description
        let validate = validateExperimentDescription(event.target.value)
        if (!validate[0]) {
            setExperimentDescriptionError(true)
            setExperimentDescriptionHelperText(validate[1])
        } else {
            setExperimentDescriptionError(false)
            setExperimentDescriptionHelperText("")
        }

        setExperimentDescription(event.target.value);
    }

    const handleExperimentHypothesisChange = (event) => {
        setExperimentHypothesis(event.target.value);
    }

    const handlePrimaryMetricsChange = (event) => {
        setPrimaryMetrics(event.target.value);
    }

    const handleSecondaryMetricsChange = (event) => {
        setSecondaryMetrics(event.target.value);
    }

    const handleGaurdrailMetricsChange = (event) => {
        setGaurdrailMetrics(event.target.value);
    }

    const handleExperimentNameChange = (event) => {

        // validate experiment name
        let validate = validateExperimentName(event.target.value)
        if (!validate[0]) {
            setExperimentNameError(true)
            setExperimentNameHelperText(validate[1])
        } else {
            setExperimentNameError(false)
            setExperimentNameHelperText("")
        }

        setExperimentName(event.target.value);
    }

    const callbackOfOverriddenUsers = (data) => {

        let updatedOverriddenUsers = {}
        data.forEach((row) => {
            updatedOverriddenUsers[row.userid] = { cohortNo: row.cohort, cohortConfig: row.cohortConfig }
        })
        setOverriddenUsers(updatedOverriddenUsers)
        props.handleSuccess({
            message: "Overridden users updated successfully",
            data: updatedOverriddenUsers,
        })
    }

    const callbackOfEditAccessData = (data) => {
        let updatedEditAccess = []
        data.forEach((row) => {
            updatedEditAccess.push(row.email)
        })

        setEditAccess(updatedEditAccess)
        props.handleSuccess({
            message: "Edit Access updated successfully",
            data: updatedEditAccess,
        })
    }

    const getUpdatedByEmail = () => {
        let updatedByEmail = getUserEmail()
        return updatedByEmail
    }

    const handleSubmitButton = () => {

        // validate userIds

        for (const [key, value] of Object.entries(overriddenUsers)) {
            let validate = validateUserId(key)
            if (!validate[0]) {
                alert("Invalid userId: " + key + " " + validate[1])
                return
            }

            let cohortValidation = validateCohort(value)
            if (!cohortValidation[0]) {
                alert("Invalid cohort: " + value + " " + cohortValidation[1])
                return
            }
        }

        // validate experimentName & experimentDescription
        if (experimentNameError || experimentDescriptionError) {
            alert("Please check the inputs")
            return
        }

        // validate experiment state
        if (experimentState === 1) {
            // if experiment state is inactive, then there should be atleast one active version
            if (props.experimentMetadata?.latestVersion === 0) {
                alert("Experiment state cannot be active as there are no active versions")
                return
            }
        }

        const data = {
            expId: experimentId,
            expName: experimentName,
            expDescription: experimentDescription,
            expHypothesis: experimentHypothesis,
            primaryMetrics: primaryMetrics,
            secondaryMetrics: secondaryMetrics,
            gaurdrailMetrics: gaurdrailMetrics,
            state: experimentState,
            overriddenUsers: overriddenUsers,
            updatedBy: getUpdatedByEmail(),
            editAccess: editAccess,
        }

        props.showLoader()
        axios.put("/api/experiment/metadata/update", data)
            .then((response) => {
                props.hideLoader()
                return response.data;
            })
            .then((data) => {
                props.hideLoader()
                props.handleSuccess(data)
                props.refresh()
                handleModalClose();
            })
            .catch((error) => {
                props.hideLoader()
                props.handleError(error)
                props.refresh()
            })
    }

    // utilities

    const getStateIntFromValue = (value) => {
        if (value === "Inactive") return 0
        if (value === "Active") return 1
        if (value === "Completed") return 2
    }

    const getExperimentIdBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    disabled
                    fullWidth
                    id="experiment-id-box"
                    label="Experiment ID"
                    variant="outlined"
                    value={experimentId}
                />
            </div>
        )
    }

    const getLatestVersionBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    disabled
                    fullWidth
                    id="latest-version"
                    label="Latest Version"
                    variant="outlined"
                    value={props.experimentMetadata?.latestVersion}
                />
            </div>
        )
    }

    const getExperimentNameBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-name-box"
                    label="Experiment Name"
                    variant="outlined"
                    value={experimentName}
                    onChange={(event) => handleExperimentNameChange(event)}
                    error={experimentNameError}
                    helperText={experimentNameHelperText}
                />
            </div>
        )
    }

    const getExperimentDescriptionBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-description-box"
                    label="Experiment Description"
                    variant="outlined"
                    value={experimentDescription}
                    onChange={(event) => handleExperimentDescriptionChange(event)}
                    error={experimentDescriptionError}
                    helperText={experimentDescriptionHelperText}
                />
            </div>
        )
    }

    const getExperimentHypothesisBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-hypothesis-box"
                    label="Experiment Hypothesis"
                    variant="outlined"
                    value={experimentHypothesis}
                    onChange={(event) => handleExperimentHypothesisChange(event)}
                    error={false}
                    helperText={""}
                />
            </div>
        )
    }

    const getPrimaryMetricsBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-primary-metrics-box"
                    label="Primary Metrics (OEC)"
                    variant="outlined"
                    value={primaryMetrics}
                    onChange={(event) => handlePrimaryMetricsChange(event)}
                    error={false}
                    helperText={""}
                />
            </div>
        )
    }

    const getSecondaryMetricsBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-secondary-metrics-box"
                    label="Secondary Metrics"
                    variant="outlined"
                    value={secondaryMetrics}
                    onChange={(event) => handleSecondaryMetricsChange(event)}
                    error={false}
                    helperText={""}
                />
            </div>
        )
    }

    const getGaurdrailMetricsBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-gaurdrail-metrics-box"
                    label="Gaurdrail Metrics"
                    variant="outlined"
                    value={gaurdrailMetrics}
                    onChange={(event) => handleGaurdrailMetricsChange(event)}
                    error={false}
                    helperText={""}
                />
            </div>
        )
    }

    const getExperimentStateDropDown = () => {
        return (
            <FormControl className={classes.formControl}>
                <InputLabel id="simple-select-label">State</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="demo-simple-select"
                    value={experimentState}
                    label="State"
                    onChange={(event) => handleExperimentStateChange(event)}
                >
                    <MenuItem value={0}>Inactive</MenuItem>
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={2}>Completed</MenuItem>
                </Select>
            </FormControl>
        )
    }

    const getRowsForOverriddenUsers = () => {
        let rows = []

        if (overriddenUsers === undefined || overriddenUsers === null) return rows

        for (const [key, value] of Object.entries(overriddenUsers)) {
            rows.push({
                userid: key,
                cohort: value.cohortNo,
                cohortConfig: value.cohortConfig,
            })
        }
        return rows
    }

    const getOverriddenUsersBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Overridden Users
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.overriddenUsersTable}>
                    <ABTable
                        rows={getRowsForOverriddenUsers()}
                        columns={OverriddenUsersTableHeaders}
                        isEditable={true}
                        tableTitle={"Overridden Users"}
                        callbackWithUpdatedData={(data) => callbackOfOverriddenUsers(data)}
                    />
                </div>
            </Paper>
        )
    }

    const getRowsForEditAccessTable = () => {
        let rows = []

        if (editAccess === undefined || editAccess === null || editAccess.length === 0) return rows

        // filter createdBy from the editAccess list
        let createdBy = props.experimentMetadata?.createdBy
        let filteredEditAccess = editAccess.filter((email) => email !== createdBy)

        filteredEditAccess.forEach((row) => {
            rows.push({
                email: row
            })
        })
        return rows
    }

    const getEditAccessBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Edit Access
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.editAccessTable}>
                    <ABTable
                        rows={getRowsForEditAccessTable()}
                        columns={ExperimentEditAccessHeaders}
                        isEditable={true}
                        tableTitle={"Edit Access"}
                        callbackWithUpdatedData={(data) => callbackOfEditAccessData(data)}
                    />
                </div>
            </Paper>
        )
    }

    const getSubmitButton = () => {
        return (
            <div className={classes.submitButton}>
                <Button
                    size="large"
                    style={{ width: 300, marginRight: 60 }}
                    variant="contained"
                    color="success"

                    onClick={() => handleSubmitButton()}
                >
                    Save Changes
                </Button>
            </div>
        )
    }

    const getExperimentInfoJson = () => {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Json data for Experiment
                </AccordionSummary>
                <AccordionDetails>
                    <Paper className={classes.blobPaper}>
                        <ReactJson
                            collapsed={false}
                            src={{
                                expId: experimentId,
                                expName: experimentName,
                                expDescription: experimentDescription,
                                expHypothesis: experimentHypothesis,
                                primaryMetrics: primaryMetrics,
                                secondaryMetrics: secondaryMetrics,
                                gaurdrailMetrics: gaurdrailMetrics,
                                state: experimentState,
                                overriddenUsers: overriddenUsers,
                                updatedBy: getUpdatedByEmail(),
                                editAccess: editAccess,
                            }}
                            theme={"summerfruit"}
                            iconStyle="square"
                        />
                    </Paper>
                </AccordionDetails>
            </Accordion>
        )
    }

    // render
    const { classes } = props
    return (
        <Modal
            open={props.showEditExperimentMetadataModal}
            onClose={() => handleModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes.editExperimentMetadataBox}>
                <div className={classes.header}>
                    <div style={{ flexGrow: 1 }}>
                        <Typography className={classes.modalTitle} variant="h5" component="h2">
                            Edit Experiment Metadata
                        </Typography>
                    </div>
                    {getSubmitButton()}
                    <IconButton
                        edge="start"
                        variant="contained"
                        color="inherit"
                        onClick={() => handleModalClose()}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                <div id="modal-modal-description">
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                            {getExperimentIdBox()}
                            {getExperimentNameBox()}
                            {getExperimentDescriptionBox()}
                            {getExperimentHypothesisBox()}
                            {getPrimaryMetricsBox()}
                            {getSecondaryMetricsBox()}
                            {getGaurdrailMetricsBox()}
                            {getLatestVersionBox()}
                            {getExperimentStateDropDown()}
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} style={{ padding: 15 }}>
                            <br />
                            {getOverriddenUsersBox()}
                            <br />
                            {getEditAccessBox()}
                            <br />
                            {getExperimentInfoJson()}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal >
    )
}

EditExperimentMetadata.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withLoader(withResponseHandler(withStyles(EditExperimentMetadata, useStyles)))