import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GetUserCohort from "./utilityComponents/GetUserCohort";
import VersionDifference from "./utilityComponents/VersionDifference";

const useStyles = (theme) => ({
    experimentUtilities: {
        padding: theme.spacing(2),
    },
});

const getUserCohortAccordion = () => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                Get User Cohort
            </AccordionSummary>

            <AccordionDetails>
                <GetUserCohort />
            </AccordionDetails>
        </Accordion>
    )
}

const getVersionDifferenceAccordion = () => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                Version Difference
            </AccordionSummary>

            <AccordionDetails>
                <VersionDifference />
            </AccordionDetails>
        </Accordion>
    )
}

const ExperimentUtilities = (props) => {

    // return
    const { classes } = props;
    return (
        <div className={classes.experimentUtilities}>
            {getUserCohortAccordion()}
            {getVersionDifferenceAccordion()}
        </div>
    )

};

ExperimentUtilities.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(ExperimentUtilities, useStyles);
