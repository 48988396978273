import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import { withStyles } from "tss-react/mui";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { PropTypes } from "prop-types";
import RewardDropDown from "../components/RewardDropDown";
import DropBox from "../components/DropBox";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    margin: "5vh 10vw",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const SeasonUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rewardData, setRewardData] = useState({
    0: {},
    4: {},
    10: {},
    15: {},
    22: {},
    28: {},
  });
  const [seasonNo, setSeasonNo] = useState(0);
  const [configNo, setConfigNo] = useState(0);
  const [currServer, setCurrServer] = useState(0);
  const [mainFiles, setMainFiles] = useState([]);
  const [bgFiles, setBgFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [nextStartTime, setNextStartTime] = useState(new Date());

  useEffect(() => {
    getSeasonInfo();
  }, []);

  const getSeasonInfo = () => {
    setLoading(true);
    axios
      .get("/api/getSeasonInfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          setConfigNo(data["SCN"]);
          setSeasonNo(data["SN"]);
        }
        setLoading(false);
      });
  };

  // Validate if all the fields are filled before publishing
  const checkValidity = (seasonData) => {
    let rewardData = seasonData["rewardData"];
    for (let i in rewardData) {
      if (Object.keys(rewardData[i]).length == 0) {
        return false;
      }
    }
    if (mainFiles.length <= 0 || bgFiles.length <= 0) {
      return false;
    }
    let seasonStartTime = seasonData["startDate"];
    let seasonEndTime = seasonData["endDate"];
    if (
      seasonStartTime == Date() ||
      seasonEndTime == Date() ||
      nextStartTime == Date()
    ) {
      return false;
    }
    return true;
  };

  const publishSeason = (data) => {
    let seasonData = {
      reward_data: data,
      start_time: startTime,
      end_time: endTime,
      next_start_time: nextStartTime,
    };
    let isDataValid = checkValidity(seasonData);
    if (!isDataValid) {
      alert(
        "MISSING FIELDS. All the fields are mandatory. \nPlease check if you have uploaded both the main files and background files. \nPlease check if you have updated rewards.",
      );
      return;
    }
    setLoading(true);
    let url = `/api/publishSeason/dev/${currServer}/season/${seasonNo}/${configNo}`;
    axios
      .post(url, seasonData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed: " + data.err, {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("Publish Successful", {
            variant: "success",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const addFile = (file, type) => {
    if (type == "bg") {
      setBgFiles((current) => [...current, file]);
    } else {
      setMainFiles((current) => [...current, file]);
    }
  };

  const uploadSeasonAssets = (file, type) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = `/api/file/seasonAsset/${seasonNo}/${type}`;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          addFile(data, type);
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadSeasonAssets(file, type);
    }
  };

  const tiers = () => {
    let tierData = [];
    for (let i in rewardData) {
      tierData.push(
        <Grid item xs={6}>
          <RewardDropDown
            header={"Bonus tier : " + i}
            tier={i}
            rewardData={rewardData}
            setReward={setRewardData}
          />
        </Grid>,
      );
    }
    return tierData;
  };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper heading="Season Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <div style={{ margin: "5vh 10vw" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                label="Season Number:"
                value={seasonNo}
                onChange={(event) => {
                  setSeasonNo(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                label="Season Config Number:"
                value={configNo}
                onChange={(event) => {
                  setConfigNo(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={currServer}
                  onChange={(event) => {
                    setCurrServer(event.target.value);
                  }}
                >
                  <MenuItem value={0}>Dev</MenuItem>
                  <MenuItem value={1}>Production</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => {
                  publishSeason(rewardData);
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                size="large"
                disabled={loading}
              >
                Publish
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Start Date"}
                type="datetime-local"
                value={startTime}
                className={classes.textField}
                onChange={(event) => {
                  setStartTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"End Date"}
                type="datetime-local"
                value={endTime}
                className={classes.textField}
                onChange={(event) => {
                  setEndTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Next Start Date"}
                type="datetime-local"
                value={nextStartTime}
                className={classes.textField}
                onChange={(event) => {
                  setNextStartTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Season Assets: </h2>
              <DropBox
                type="main"
                uploadedFiles={mainFiles}
                loading={loading}
                handleDropFile={handleDropFile}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Season BG Assets: </h2>
              <DropBox
                type="bg"
                uploadedFiles={bgFiles}
                loading={loading}
                handleDropFile={handleDropFile}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Rewards: </h2>
              <div className={classes.header}>
                <Grid container spacing={3}>
                  {tiers()}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Wrapper>
    </div>
  );
};

SeasonUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(SeasonUpload, useStyles);
