import { PropTypes } from "prop-types";
import withResponseHandler from "../../components/ResponseHandler";
import { withStyles } from "tss-react/mui";
import { Button, Modal, TextField, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import axios from "axios";
import withLoader from "../../components/Loader";
import {
    validateExperimentDescription,
    validateExperimentId,
    validateExperimentName
} from "../validators/ABExperimentationValidators";
import { getUserEmail } from "../../utils/tokenUtils";

const useStyles = (theme) => ({
    addExperimentBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        width: '35%',
        backgroundColor: '#383838',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    textField: {
        padding: theme.spacing(2),
    },
    submitButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    modalTitle: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#8FC9F9'
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
})

const AddExperiment = (props) => {

    // hooks
    const [experimentId, setExperimentId] = useState("")
    const [experimentIdError, setExperimentIdError] = useState(false)
    const [experimentIdHelperText, setExperimentIdHelperText] = useState("")

    const [experimentName, setExperimentName] = useState("")
    const [experimentNameError, setExperimentNameError] = useState(false)
    const [experimentNameHelperText, setExperimentNameHelperText] = useState("")

    const [experimentDescription, setExperimentDescription] = useState("")
    const [experimentDescriptionError, setExperimentDescriptionError] = useState(false)
    const [experimentDescriptionHelperText, setExperimentDescriptionHelperText] = useState("")

    // event handlers

    const handleExperimentIdChange = (event) => {

        // validate experiment id
        let validate = validateExperimentId(event.target.value)
        if (!validate[0]) {
            setExperimentIdError(true)
            setExperimentIdHelperText(validate[1])
        } else {
            setExperimentIdError(false)
            setExperimentIdHelperText("")
        }

        setExperimentId(event.target.value)
    }

    const handleExperimentNameChange = (event) => {

        // validate experiment name
        let validate = validateExperimentName(event.target.value)
        if (!validate[0]) {
            setExperimentNameError(true)
            setExperimentNameHelperText(validate[1])
        } else {
            setExperimentNameError(false)
            setExperimentNameHelperText("")
        }

        setExperimentName(event.target.value)
    }

    const handleExperimentDescriptionChange = (event) => {

        // validate experiment description
        let validate = validateExperimentDescription(event.target.value)
        if (!validate[0]) {
            setExperimentDescriptionError(true)
            setExperimentDescriptionHelperText(validate[1])
        } else {
            setExperimentDescriptionError(false)
            setExperimentDescriptionHelperText("")
        }

        setExperimentDescription(event.target.value)
    }

    const handleSuccess = (data) => {
        props.handleSuccess(data)
        props.refresh()
    }

    const handleError = (error) => {
        props.handleError(error)
    }

    const handleSubmit = () => {

        // if any of the fields are invalid, return
        if (experimentIdError || experimentNameError || experimentDescriptionError) {
            alert("Please fill all the fields correctly before submitting")
            return
        }

        let createdByEmail = getUserEmail()
        const data = {
            expId: experimentId,
            expName: experimentName,
            expDescription: experimentDescription,
            createdBy: createdByEmail,
        }

        props.showLoader()
        axios.post("/api/experiment/metadata/add", data)
            .then((response) => {
                props.hideLoader()
                return response.data;
            })
            .then((data) => {
                props.hideLoader()
                handleSuccess(data)
                handleModalClose()
            })
            .catch((error) => {
                props.hideLoader()
                handleError(error)
                handleModalClose()
            })

    }

    const handleModalClose = () => {
        props.onHandleClose()
    }

    // utilities
    const getExperimentIdBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    fullWidth
                    id="experiment-id-box"
                    label="Experiment ID"
                    variant="outlined"
                    onChange={(event) => handleExperimentIdChange(event)}
                    error={experimentIdError}
                    helperText={experimentIdHelperText}
                />
            </div>
        )
    }

    const getExperimentNameBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    fullWidth
                    id="experiment-name-box"
                    label="Experiment Name"
                    variant="outlined"
                    onChange={(event) => handleExperimentNameChange(event)}
                    error={experimentNameError}
                    helperText={experimentNameHelperText}
                />
            </div>
        )
    }

    const getExperimentDescriptionBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    fullWidth
                    id="experiment-description-box"
                    label="Experiment Description"
                    variant="outlined"
                    onChange={(event) => handleExperimentDescriptionChange(event)}
                    error={experimentDescriptionError}
                    helperText={experimentDescriptionHelperText}
                />
            </div>
        )
    }

    const getSubmitButton = () => {
        return (
            <div className={classes.submitButton}>
                <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            </div>
        )
    }

    // return
    const { classes } = props;
    return (
        <Modal
            open={props.shouldShow}
            onClose={() => handleModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes.addExperimentBox}>
                <div id="modal-modal-title" className={classes.header}>
                    <Typography className={classes.modalTitle} variant="h5" component="h2">
                        Add Experiment
                    </Typography>
                    <IconButton
                        edge="start"
                        variant="contained"
                        color="inherit"
                        onClick={() => handleModalClose()}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div id="modal-modal-description">
                    {getExperimentIdBox()}
                    {getExperimentNameBox()}
                    {getExperimentDescriptionBox()}
                    {getSubmitButton()}
                </div>
            </div>
        </Modal>
    )
}

AddExperiment.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withLoader(withResponseHandler(withStyles(AddExperiment, useStyles)));