import { PropTypes } from "prop-types";
import React from "react";
import { withStyles } from "tss-react/mui";
import withResponseHandler from "./ResponseHandler";
import {
    Paper, Container, Box, Tabs, Tab, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";

const useStyles = (theme) => ({
    tableTitle: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#8FC9F9'
    },
    updateButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    dataGridBox: {
        minHeight: '500px',
        maxHeight: 'auto',
    }
})

const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const BiasTable = (props) => {
    const { classes } = props;
    const [biasTabValue, setBiasTabValue] = React.useState(0);

    const handleBiasTabChange = (event, newValue) => {
        setBiasTabValue(newValue);
    };

    return (
        <Container>
            <Tabs value={biasTabValue} onChange={handleBiasTabChange} aria-label="experiment tabs">
                {props.biasData.map((exp, index) => (
                    <Tab label={exp.expName} key={index} />
                ))}
            </Tabs>
            {props.biasData.map((exp, index) => (
                <TabPanel value={biasTabValue} index={index} key={exp.expId}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Active Experiment Country</TableCell>
                                    <TableCell>Experiment Country</TableCell>
                                    <TableCell>Deviation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {exp.bias.map((bias, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{bias.activeExpCountry}</TableCell>
                                        <TableCell>{bias.expCountry}</TableCell>
                                        <TableCell>{bias.deviation}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            ))}
        </Container>
    )
}

BiasTable.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withResponseHandler(withStyles(BiasTable, useStyles))