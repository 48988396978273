const enum ServerEventType {
  CannonGame,
  TreasureRoad = 2,
  MultiCannonGame,
  ktmV4 = 5,
  ktmV4Ludo,
}

const enum ServerSubEventType {
  cannon,
  football,
}

export const ServerEventTypes = new Map([
  [ServerEventType.CannonGame, "KTM Event"],
  [ServerEventType.MultiCannonGame, "Multi KTM Event"],
  [ServerEventType.TreasureRoad, "TreasureRoad Event"],
  [ServerEventType.ktmV4, "KTMV4 Event"],
  [ServerEventType.ktmV4Ludo, "KTMV4 Event"],
]);

export const ServerSubEventTypes = new Map([
  [ServerSubEventType.cannon, "Cannon"],
  [ServerSubEventType.football, "Football"],
]);

const enum EventNameTypes {
  ktm,
  ktmV3,
  football,
  treasureRoad,
  ktmV4,
}

export const EventNames = new Map([
  [EventNameTypes.ktm, "KTM Event"],
  [EventNameTypes.ktmV3, "KTMV3 Event"],
  [EventNameTypes.football, "Football Event"],
  [EventNameTypes.treasureRoad, "TreasureRoad Event"],
  [EventNameTypes.ktmV4, "KTMV4 Event"],
]);

/* For Club Leaderboard Assets*/

export const enum LeaderboardCategory {
  club,
  sender,
  receiver,
}

export const LeaderboardCategoryNames = [
  { value: LeaderboardCategory.club, id: "club", label: "Club" },
  { value: LeaderboardCategory.sender, id: "sender", label: "Gifter" },
  { value: LeaderboardCategory.receiver, id: "receiver", label: "Reciever" },
];

export const enum ClubRewards {
  backgrounds,
  card,
}

export const enum UserRewards {
  profileFrames,
  chatBubbles,
}

export const ClubRewardNames = [
  { value: ClubRewards.backgrounds, id: "backgrounds", label: "Background" },
  { value: ClubRewards.card, id: "cards", label: "Card Frame" },
];

export const UserRewardNames = [
  {
    value: UserRewards.profileFrames,
    id: "profileFrames",
    label: "Profile Frames",
  },
  { value: UserRewards.chatBubbles, id: "chatBubbles", label: "Chat Bubbles" },
];

export const RankList = [
  { value: 0, minRank: 1, maxRank: 1, label: "1", id: 1 },
  { value: 1, minRank: 2, maxRank: 2, label: "2", id: 2 },
  { value: 2, minRank: 3, maxRank: 3, label: "3", id: 3 },
];

export const EventWarnings = new Map([
  [ 
    "eventID already exists in staging",
    "Warning: EventId already exists in staging, do you still want to continue ?",
  ],
]);

export const OverriddenUsersTableHeaders = [
    {
        field: 'userid',
        headerName: 'User ID',
        width: 180,
        editable: true
    },
    {
        field: 'cohort',
        headerName: 'Cohort',
        type: 'number',
        width: 150,
        align: 'left',
        headerAlign: 'left',
        editable: true,
    },
    {
        field: 'cohortConfig',
        headerName: 'Cohort Config',
        type: 'string',
        width: 150,
        align: 'left',
        headerAlign: 'left',
        editable: true,
    },
]

export const VariantInfoTableHeaders = [
    {
        field: 'cohortNo',
        headerName: 'Cohort No',
        width: 100,
        type: 'number',
        editable: true
    },
    {
        field: 'cohortPercentage',
        headerName: 'Cohort %',
        width: 100,
        type: 'number',
        editable: true
    },
    {
        field: 'cohortDetails',
        headerName: 'Cohort Details',
        width: 180,
        editable: true
    },
    {
        field: 'cohortConfig',
        headerName: 'Cohort Config',
        width: 180,
        editable: true
    }
]

export const ExperimentMetadataTableHeaders = [
    {
        field: 'index',
        headerName: 'Index',
        width: 180,
        editable: false
    },
    {
        field: 'details',
        headerName: 'Details',
        width: 420,
        editable: false
    }
]

export const ExperimentEditAccessHeaders = [
    {
        field: 'email',
        headerName: 'Email',
        width: 350,
        editable: true
    },
]

export const ExperimentTimelineTableHeaders = [
    {
        field: 'timelineId',
        headerName: 'Timeline ID',
        width: 150,
        editable: true,
    },
    {
        field: 'version',
        headerName: 'Version',
        width: 90,
        type: 'number',
        editable: true,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        width: 150,
        type: 'dateTime',
        valueGetter: (params: { row: { startDate: string | number | Date; }; }) => new Date(params.row.startDate),
        editable: true,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        width: 150,
        type: 'dateTime',
        valueGetter: (params: { row: { endDate: string | number | Date; }; }) => new Date(params.row.endDate),
        editable: true,
    },
]

export const CountryList = [
    [
        "IN",
        "India"
    ],
    [
        "PK",
        "Pakistan"
    ],
    [
        "SA",
        "Saudi Arabia"
    ],
    [
        "OM",
        "Oman"
    ],
    [
        "ID",
        "Indonesia"
    ],
    [
        "SD",
        "Sudan"
    ],
    [
        "BR",
        "Brazil"
    ],
    [
        "CO",
        "Colombia"
    ],
    [
        "PA",
        "Panama"
    ],
    [
        "AE",
        "UAE"
    ],
    [
        "GB",
        "UK"
    ],
    [
        "KE",
        "Kenya"
    ],
    [
        "ES",
        "Spain"
    ],
    [
        "US",
        "USA"
    ],
    [
        "SO",
        "Somalia"
    ],
    [
        "AF",
        "Afghanistan"
    ],
    [
        "AL",
        "Albania"
    ],
    [
        "DZ",
        "Algeria"
    ],
    [
        "AS",
        "American Samoa"
    ],
    [
        "AD",
        "Andorra"
    ],
    [
        "AO",
        "Angola"
    ],
    [
        "AI",
        "Anguilla"
    ],
    [
        "AQ",
        "Antarctica"
    ],
    [
        "AG",
        "Antigua & B"
    ],
    [
        "AR",
        "Argentina"
    ],
    [
        "AM",
        "Armenia"
    ],
    [
        "AW",
        "Aruba"
    ],
    [
        "AU",
        "Australia"
    ],
    [
        "AT",
        "Austria"
    ],
    [
        "AZ",
        "Azerbaijan"
    ],
    [
        "BS",
        "Bahamas"
    ],
    [
        "BH",
        "Bahrain"
    ],
    [
        "BD",
        "Bangladesh"
    ],
    [
        "BB",
        "Barbados"
    ],
    [
        "BY",
        "Belarus"
    ],
    [
        "BE",
        "Belgium"
    ],
    [
        "BZ",
        "Belize"
    ],
    [
        "BJ",
        "Benin"
    ],
    [
        "BM",
        "Bermuda"
    ],
    [
        "BT",
        "Bhutan"
    ],
    [
        "BO",
        "Bolivia"
    ],
    [
        "BA",
        "Bosnia and Herzegovina"
    ],
    [
        "BW",
        "Botswana"
    ],
    [
        "BN",
        "Brunei Darussalam"
    ],
    [
        "BG",
        "Bulgaria"
    ],
    [
        "BF",
        "Burkina Faso"
    ],
    [
        "BI",
        "Burundi"
    ],
    [
        "KH",
        "Cambodia"
    ],
    [
        "CM",
        "Cameroon"
    ],
    [
        "CA",
        "Canada"
    ],
    [
        "KY",
        "Cayman Islands"
    ],
    [
        "CF",
        "Central African Republic"
    ],
    [
        "TD",
        "Chad"
    ],
    [
        "CL",
        "Chile"
    ],
    [
        "CN",
        "China"
    ],
    [
        "KM",
        "Comoros"
    ],
    [
        "CG",
        "Congo"
    ],
    [
        "CD",
        "Congo"
    ],
    [
        "CK",
        "Cook Islands"
    ],
    [
        "CR",
        "Costa Rica"
    ],
    [
        "CI",
        "Cote DIvoire"
    ],
    [
        "HR",
        "Croatia"
    ],
    [
        "CU",
        "Cuba"
    ],
    [
        "CY",
        "Cyprus"
    ],
    [
        "CZ",
        "Czech Republic"
    ],
    [
        "DK",
        "Denmark"
    ],
    [
        "DJ",
        "Djibouti"
    ],
    [
        "DM",
        "Dominica"
    ],
    [
        "DO",
        "Dominican Republic"
    ],
    [
        "EC",
        "Ecuador"
    ],
    [
        "EG",
        "Egypt"
    ],
    [
        "SV",
        "El Salvador"
    ],
    [
        "GQ",
        "Equatorial Guinea"
    ],
    [
        "ER",
        "Eritrea"
    ],
    [
        "EE",
        "Estonia"
    ],
    [
        "ET",
        "Ethiopia"
    ],
    [
        "FO",
        "Faroe Islands"
    ],
    [
        "FJ",
        "Fiji"
    ],
    [
        "FI",
        "Finland"
    ],
    [
        "FR",
        "France"
    ],
    [
        "GF",
        "French Guiana"
    ],
    [
        "PF",
        "French Polynesia"
    ],
    [
        "GA",
        "Gabon"
    ],
    [
        "GM",
        "Gambia"
    ],
    [
        "GE",
        "Georgia"
    ],
    [
        "DE",
        "Germany"
    ],
    [
        "GH",
        "Ghana"
    ],
    [
        "GI",
        "Gibraltar"
    ],
    [
        "GR",
        "Greece"
    ],
    [
        "GL",
        "Greenland"
    ],
    [
        "GD",
        "Grenada"
    ],
    [
        "GP",
        "Guadeloupe"
    ],
    [
        "GU",
        "Guam"
    ],
    [
        "GT",
        "Guatemala"
    ],
    [
        "GG",
        "Guernsey"
    ],
    [
        "GN",
        "Guinea"
    ],
    [
        "GW",
        "Guinea-Bissau"
    ],
    [
        "GY",
        "Guyana"
    ],
    [
        "HT",
        "Haiti"
    ],
    [
        "VA",
        "Holy See (Vatican City State)"
    ],
    [
        "HN",
        "Honduras"
    ],
    [
        "HK",
        "Hong Kong"
    ],
    [
        "HU",
        "Hungary"
    ],
    [
        "IS",
        "Iceland"
    ],
    [
        "IR",
        "Iran, Islamic Republic Of"
    ],
    [
        "IQ",
        "Iraq"
    ],
    [
        "IE",
        "Ireland"
    ],
    [
        "IM",
        "Isle of Man"
    ],
    [
        "IL",
        "Israel"
    ],
    [
        "IT",
        "Italy"
    ],
    [
        "JM",
        "Jamaica"
    ],
    [
        "JP",
        "Japan"
    ],
    [
        "JE",
        "Jersey"
    ],
    [
        "JO",
        "Jordan"
    ],
    [
        "KZ",
        "Kazakhstan"
    ],
    [
        "KI",
        "Kiribati"
    ],
    [
        "KP",
        "Korea, Democratic"
    ],
    [
        "KR",
        "Korea, Republic of"
    ],
    [
        "KW",
        "Kuwait"
    ],
    [
        "KG",
        "Kyrgyzstan"
    ],
    [
        "LA",
        "Lao People Democratic Republic"
    ],
    [
        "LV",
        "Latvia"
    ],
    [
        "LB",
        "Lebanon"
    ],
    [
        "LS",
        "Lesotho"
    ],
    [
        "LR",
        "Liberia"
    ],
    [
        "LY",
        "Libya"
    ],
    [
        "LI",
        "Liechtenstein"
    ],
    [
        "LT",
        "Lithuania"
    ],
    [
        "LU",
        "Luxembourg"
    ],
    [
        "MO",
        "Macao"
    ],
    [
        "MK",
        "Macedonia"
    ],
    [
        "MG",
        "Madagascar"
    ],
    [
        "MW",
        "Malawi"
    ],
    [
        "MY",
        "Malaysia"
    ],
    [
        "MV",
        "Maldives"
    ],
    [
        "ML",
        "Mali"
    ],
    [
        "MT",
        "Malta"
    ],
    [
        "MH",
        "Marshall Islands"
    ],
    [
        "MQ",
        "Martinique"
    ],
    [
        "MR",
        "Mauritania"
    ],
    [
        "MU",
        "Mauritius"
    ],
    [
        "MX",
        "Mexico"
    ],
    [
        "FM",
        "Micronesia, Federated States of"
    ],
    [
        "MD",
        "Moldova, Republic of"
    ],
    [
        "MC",
        "Monaco"
    ],
    [
        "MN",
        "Mongolia"
    ],
    [
        "MS",
        "Montserrat"
    ],
    [
        "MA",
        "Morocco"
    ],
    [
        "MZ",
        "Mozambique"
    ],
    [
        "MM",
        "Myanmar"
    ],
    [
        "NA",
        "Namibia"
    ],
    [
        "NR",
        "Nauru"
    ],
    [
        "NP",
        "Nepal"
    ],
    [
        "NL",
        "Netherlands"
    ],
    [
        "NC",
        "New Caledonia"
    ],
    [
        "NZ",
        "New Zealand"
    ],
    [
        "NI",
        "Nicaragua"
    ],
    [
        "NE",
        "Niger"
    ],
    [
        "NG",
        "Nigeria"
    ],
    [
        "NO",
        "Norway"
    ],
    [
        "PW",
        "Palau"
    ],
    [
        "PS",
        "Palestinian Territory, Occupied"
    ],
    [
        "PG",
        "Papua New Guinea"
    ],
    [
        "PY",
        "Paraguay"
    ],
    [
        "PE",
        "Peru"
    ],
    [
        "PH",
        "Philippines"
    ],
    [
        "PL",
        "Poland"
    ],
    [
        "PT",
        "Portugal"
    ],
    [
        "PR",
        "Puerto Rico"
    ],
    [
        "QA",
        "Qatar"
    ],
    [
        "RE",
        "Reunion"
    ],
    [
        "RO",
        "Romania"
    ],
    [
        "RU",
        "Russian Federation"
    ],
    [
        "RW",
        "RWANDA"
    ],
    [
        "KN",
        "Saint Kitts and Nevis"
    ],
    [
        "LC",
        "Saint Lucia"
    ],
    [
        "VC",
        "Saint Vincent & the Grenadines"
    ],
    [
        "WS",
        "Samoa"
    ],
    [
        "SM",
        "San Marino"
    ],
    [
        "ST",
        "Sao Tome and Principe"
    ],
    [
        "SN",
        "Senegal"
    ],
    [
        "SC",
        "Seychelles"
    ],
    [
        "SL",
        "Sierra Leone"
    ],
    [
        "SG",
        "Singapore"
    ],
    [
        "SK",
        "Slovakia"
    ],
    [
        "SI",
        "Slovenia"
    ],
    [
        "SB",
        "Solomon Islands"
    ],
    [
        "ZA",
        "South Africa"
    ],
    [
        "LK",
        "Sri Lanka"
    ],
    [
        "SR",
        "Suriname"
    ],
    [
        "SZ",
        "Swaziland"
    ],
    [
        "SE",
        "Sweden"
    ],
    [
        "CH",
        "Switzerland"
    ],
    [
        "SY",
        "Syrian Arab Republic"
    ],
    [
        "TW",
        "Taiwan"
    ],
    [
        "TJ",
        "Tajikistan"
    ],
    [
        "TZ",
        "Tanzania, United Republic of"
    ],
    [
        "TH",
        "Thailand"
    ],
    [
        "TL",
        "Timor-Leste"
    ],
    [
        "TG",
        "Togo"
    ],
    [
        "TO",
        "Tonga"
    ],
    [
        "TT",
        "Trinidad and Tobago"
    ],
    [
        "TN",
        "Tunisia"
    ],
    [
        "TR",
        "Turkey"
    ],
    [
        "TM",
        "Turkmenistan"
    ],
    [
        "TC",
        "Turks and Caicos Islands"
    ],
    [
        "TV",
        "Tuvalu"
    ],
    [
        "UG",
        "Uganda"
    ],
    [
        "UA",
        "Ukraine"
    ],
    [
        "UY",
        "Uruguay"
    ],
    [
        "UZ",
        "Uzbekistan"
    ],
    [
        "VU",
        "Vanuatu"
    ],
    [
        "VE",
        "Venezuela"
    ],
    [
        "VN",
        "Viet Nam"
    ],
    [
        "VG",
        "Virgin Islands, British"
    ],
    [
        "VI",
        "Virgin Islands, U.S."
    ],
    [
        "EH",
        "Western Sahara"
    ],
    [
        "YE",
        "Yemen"
    ],
    [
        "ZM",
        "Zambia"
    ],
    [
        "ZW",
        "Zimbabwe"
    ],
];

export function getCountryCodes() {
    let countryCodes = []

    for (let i = 0; i < CountryList.length; i++) {
        countryCodes.push(CountryList[i][0])
    }
    
    return countryCodes
}

export const CountryDataTableHeaders = [
    {
        field: 'country',
        headerName: 'Country',
        width: 180,
        editable: true,
        type: 'singleSelect',
        valueOptions: getCountryCodes()
    },
    {
        field: 'variant',
        headerName: 'Variant Id',
        width: 250,
        editable: true
    },
]
