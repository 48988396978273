import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Container, Grid, Card, Divider } from "@mui/material";
import { Component } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { saveUserDetails } from "../utils/tokenUtils";
import { withStyles } from "tss-react/mui";
import { GetAppType, AppType } from "../globals/conf.ts";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  contentCover: {
    flex: "1 0 auto",
  },
  cover: {
    backgroundColor: "#000000c4",
    display: "flex",
    alignItems: "center",
    minWidth: "70%",
    justifyContent: "space-around",
    padding: theme.spacing(2),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  icon: {
    width: 200,
    height: 200,
  },
  info: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    paddingTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    paddingBottom: theme.spacing(4),
  },
  loginContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  mainBg: {
    display: "flex",
    position: "absolute",
    flexDirection: "row",
    backgroundImage: `url(login_cover.jpg)`,
    height: "100%",
    zIndex: -1,
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
});

class Login extends Component {
  componentDidMount() {
    let client_id = "56494130403-7918hbb7ipumne2rg69dq29tvuhamg47.apps.googleusercontent.com";
    if (GetAppType() == AppType.Ludo) {
      client_id = "342113047414-lchgbg835smli61pqu47v3514gj20lg0.apps.googleusercontent.com";
    }

    window.google.accounts.id.initialize({
      client_id: client_id,
      callback: this.handleLogin,
      auto_select: true,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("btnGoogleSignIn"),
      { theme: "outline", size: "large" }, // customization attributes
    );
  }

  handleLogin = (response) => {
    axios
      .post("/oauth", { token: response.credential })
      .then((json) => json.data)
      .then((data) => {
        console.log(`post: /oauth: success, ${JSON.stringify(data)}`);
        saveUserDetails(data.name, data.image, data.email);
        this.props.setIsLoggedIn(true);
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        console.error(`post: /oauth: ${err}`);
      });
  };

  render() {
    const { classes } = this.props;
    if (this.props.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content} color="background">
          <div className={classes.mainBg} />

          <Container maxWidth="lg" className={classes.container}>
            <Grid className={classes.loginContainer} container spacing={6}>
              <Card elevation={10} className={classes.cover}>
                <div className={classes.info}>
                  <img className={classes.icon} alt="" src="icon.png"></img>
                </div>
                <Divider light orientation="vertical" flexItem />
                <div id="btnGoogleSignIn"></div>
              </Card>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Login, useStyles);
