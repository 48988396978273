import React from "react";
import { withStyles } from 'tss-react/mui';
import { Container, Typography, FormControl, Select, MenuItem, Button, TextField } from '@mui/material';
import { Component } from "react";
import { PropTypes } from "prop-types";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import DropZone from "react-drop-zone";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

class Notification extends Component {
  state = {
    diceID: "",
    type: "text",
    expiry: "",
    heading: "",
    content: "",
    heading_loc: "",
    content_loc: "",
    loading: false,
  };
  publishCSV = (file) => {

    this.setState({ loading: true });
    const data = new FormData();
    data.append("file", file);
    let url = "/api/notificaiton/csv";
    axios.post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.props.updateNotif(file.name);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handeDiceIDChange = (event) => {
    this.setState({
      diceID: event.target.value,
    });
  };

  publishTextNotif = () => {
    this.setState({ loading: true });
    let url = "/api/textnotificaiton";
    axios.post(url, this.state)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  publishNotification = () => {
    this.setState({ loading: true });

    let url =
      "/api/notificaiton/publish/expiry/" +
      this.state.expiry +
      "/diceID/" +
      this.state.diceID;

    if (this.state.diceID == "") {
      url =
        "/api/notificaiton/publish/expiry/" +
        this.state.expiry;
    }
    axios.get(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {

        } else {
          this.props.updateNotif("");
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getInputList = () => {
    const { classes } = this.props;
    switch (this.state.type) {
      case "dice":
        return (
          <div>
            <TextField
              id="outlined-basic"
              label="Dice ID"
              value={this.state.diceID}
              onChange={this.handeDiceIDChange}
              variant="outlined"
            />
            <br></br>
            <br></br>
            <TextField
              label={"Expiry"}
              type="datetime-local"
              value={this.state.expiry}
              className={classes.textField}
              name="expiry"
              variant="outlined"
              onChange={(event) => {
                this.setState({
                  expiry: event.target.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        );
        break;
      case "text":
        return (
          <div>
            <TextField
              id="outlined-basic"
              label="Heading Key"
              value={this.state.heading}
              onChange={(event) => {
                this.setState({ heading: event.target.value });
              }}
              fullWidth
              variant="outlined"
            />
            <br></br>
            <br></br>
            <TextField
              id="outlined-multiline-static"
              multiline
              label="Localisation"
              value={this.state.heading_loc}
              rows={15}
              onChange={(event) => {
                this.setState({ heading_loc: event.target.value });
              }}
              variant="outlined"
              fullWidth
            />
            <br></br>
            <br></br>
            <TextField
              id="outlined-basic"
              label="Content Key"
              value={this.state.content}
              onChange={(event) => {
                this.setState({ content: event.target.value });
              }}
              fullWidth
              variant="outlined"
            />
            <br></br>
            <br></br>
            <TextField
              id="outlined-multiline-static"
              multiline
              label="Localisation"
              value={this.state.content_loc}
              rows={15}
              onChange={(event) => {
                this.setState({ content_loc: event.target.value });
              }}
              variant="outlined"
              fullWidth
            />
          </div>
        );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Wrapper heading="Notification" setIsLoggedIn={this.props.setIsLoggedIn}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.headerContainer}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={this.state.type}
                  onChange={(event) => {
                    this.setState({ type: event.target.value });
                  }}
                >
                  <MenuItem value={"text"}>Text</MenuItem>
                  <MenuItem value={"dice"}>Dice</MenuItem>
                </Select>
              </FormControl>
              <div>
                <DropZone
                  onDrop={(file, text) => {
                    this.publishCSV(file);
                  }}
                >
                  {({ over, overDocument }) => (
                    <Button color="primary" variant="contained">
                      Upload
                    </Button>
                  )}
                </DropZone>
                <Button
                  onClick={() => {
                    if (this.state.type == "dice") {
                      this.publishNotification();
                    } else {
                      this.publishTextNotif();
                    }
                  }}
                  style={{ marginLeft: 20 }}
                  color="primary"
                  variant="contained"
                  disabled={this.props.user.Notifications.FileName == ""}
                >
                  Publish
                </Button>
              </div>
            </div>
            <br></br>
            <Typography variant="h4">
              {this.props.user.Notifications.FileName}
            </Typography>
            <br></br>
            <br></br>
            {this.getInputList()}
            <br></br>
            <br></br>
            <br></br>
          </Container>
        </Wrapper>
      </div>
    );
  }
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Notification, useStyles);
