import React from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Component } from "react";
import { PropTypes } from "prop-types";
import Wrapper from "../components/Wrapper";
import LiveEvent from "../components/LiveEvent";
import LiveConfigVersion from "../components/LiveConfigVersion";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loaderWrapper: {
    display: "flex",
    marginTop: theme.spacing(10),
    justifyContent: "center",
  },
});

class Live extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Wrapper heading="Live Events" setIsLoggedIn={this.props.setIsLoggedIn}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.header}>
              <Typography color="textSecondary" variant="h5">
                Live Config Versions
              </Typography>
              <div style={{ flexGrow: 1 }}></div>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={this.props.currentLiveServer}
                  onChange={this.props.handleLiveInfoChange}
                >
                  <MenuItem value={0}>Production</MenuItem>
                  <MenuItem value={1}>Staging</MenuItem>
                  <MenuItem value={2}>Dev</MenuItem>
                </Select>
              </FormControl>
            </div>
            {this.props.liveLoading ? (
              <div className={classes.loaderWrapper}>
                <CircularProgress
                  className={classes.loader}
                  color="secondary"
                />
              </div>
            ) : (
              <LiveConfigVersion liveInfo={this.props.liveInfo} />
            )}
          </Container>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.header}>
              <Typography color="textSecondary" variant="h5">
                Event Details
              </Typography>
              <div style={{ flexGrow: 1 }}></div>
            </div>
            {this.props.liveLoading ? (
              <div className={classes.loaderWrapper}>
                <CircularProgress
                  className={classes.loader}
                  color="secondary"
                />
              </div>
            ) : (
              <LiveEvent liveInfo={this.props.liveInfo}></LiveEvent>
            )}
          </Container>
        </Wrapper>
      </div>
    );
  }
}

Live.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Live, useStyles);
