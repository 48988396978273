import {
    Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withStyles } from 'tss-react/mui';
import { PropTypes } from "prop-types";
import axios from "axios";
import withResponseHandler from "../../components/ResponseHandler";
import ABTable from "../../components/ABTable";
import ViewExperimentMetadata from "./ViewExperimentMetadata";
import ViewExperimentDetails from "./ViewExperimentDetails";
import withLoader from "../../components/Loader";

// use styles hook
const useStyles = (theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    root: {
        width: "80%",
    },
    container: {
        paddingTop: theme.spacing(5),
        padding: theme.spacing(2),
    },
    viewExperimentDetailsButton: {

    },
    editExperimentMetadataButton: {

    },
    textField: {
        padding: theme.spacing(1),
    },
    experimentListLayout: {
        padding: theme.spacing(0),
    }
})

const ExperimentList = (props) => {

    // vars
    const ExperimentListTableHeaders = [
        {
            field: 'expId',
            headerName: 'Experiment ID',
            width: 180,
            editable: true
        },
        {
            field: 'expName',
            headerName: 'Experiment Name',
            width: 180,
            editable: true
        },
        {
            field: 'latestVersion',
            headerName: 'Version',
            width: 80,
            type: 'number',
            editable: true
        },
        {
            field: 'state',
            headerName: 'State',
            width: 130,
            editable: true
        },
        {
            field: 'viewButton',
            headerName: 'Metadata',
            width: 100,
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => handleMetadataClickCallback(params.row)}>
                    view
                </Button>
            ),
            editable: true
        },
        {
            field: 'detailsButton',
            headerName: 'Version data',
            width: 100,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={() => handleVersionDataClickCallback(params.row)}>
                    view
                </Button>
            ),
            editable: true
        }
    ]

    // hooks
    const [showViewExperimentMetadataModal, setShowViewExperimentMetadataModal] = useState(false)
    const [showViewExperimentDetailsModal, setShowViewExperimentDetailsModal] = useState(false)
    const [experimentData, setExperimentData] = useState({})
    const [experimentMetadata, setExperimentMetadata] = useState({})
    const [experimentList, setExperimentList] = useState([])

    useEffect(() => {
        setExperimentList(props.experimentList)
    }, [props.experimentList])

    // event handlers

    const handleViewExperimentMetadataModalClose = () => {
        setShowViewExperimentMetadataModal(false)
    }

    const handleViewExperimentDetailsModalClose = () => {
        setShowViewExperimentDetailsModal(false)
    }

    const handleExperimentDetailsSuccess = (data) => {
        setShowViewExperimentDetailsModal(true)
        setExperimentData(data)
    }

    const handleError = (error) => {
        props.handleError(error)
    }

    const handleMetadataClickCallback = (rowData) => {
        setExperimentMetadata(rowData)
        setShowViewExperimentMetadataModal(true)
    }

    const handleVersionDataClickCallback = (rowData) => {
        setExperimentMetadata(rowData)

        const url = "/api/experiment/details/id/" + rowData.expId + "/version/" + rowData.latestVersion
        props.showLoader()
        axios.get(url)
            .then((response) => {
                props.hideLoader()
                return response.data
            })
            .then((data) => {
                props.hideLoader()
                handleExperimentDetailsSuccess(data.data)
            })
            .catch((error) => {
                props.hideLoader()
                handleError(error)
            })
    }

    // utilities

    const getStateAsString = (state) => {
        if (state === 0) {
            return "Inactive"
        } else if (state === 1) {
            return "Active"
        } else if (state === 2) {
            return "Completed"
        } else if (state === 3) {
            return "All"
        }
    }

    // const getStaticTypography = (label, text, color) => {
    //     return (
    //         <div className={classes.textField}>
    //             <TextField
    //                 disabled
    //                 label={label}
    //                 value={text}
    //                 variant="outlined"
    //                 sx={{
    //                     '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    //                         borderColor: color,
    //                     },
    //                 }}
    //             />
    //             <br></br>
    //         </div>
    //     )
    // }

    const getRowsFromExperimentList = (experimentList) => {
        if (experimentList == null) {
            return []
        }

        return experimentList.map((experiment) => {
            return {
                expId: experiment.expId,
                expName: experiment.expName,
                expSalt: experiment.expSalt,
                latestVersion: experiment.latestVersion,
                state: getStateAsString(experiment.state),
                expDescription: experiment.expDescription,
                expHypothesis: experiment.expHypothesis,
                primaryMetrics: experiment.primaryMetrics,
                secondaryMetrics: experiment.secondaryMetrics,
                gaurdrailMetrics: experiment.gaurdrailMetrics,
                createdAt: experiment.createdAt,
                updatedAt: experiment.updatedAt,
                createdBy: experiment.createdBy,
                overriddenUsers: experiment.overriddenUsers,
                editAccess: experiment.editAccess,
                revision: experiment.revision,
            }
        })
    }

    const getExperimentMetadataLayout = (experimentList) => {
        return (
            <div className={classes.experimentListLayout}>
                <ABTable
                    rows={getRowsFromExperimentList(experimentList)}
                    columns={ExperimentListTableHeaders}
                    isEditable={false}
                    tableTitle={"Experiment List"}
                />
            </div>
        )
    }

    const getExperimentMetadata = () => {

        if (!Array.isArray(props.experimentList)) {
            console.error('props.experimentList is not an array');
            return null
        }

        return (
            <>
                {getExperimentMetadataLayout(experimentList)}

                <ViewExperimentDetails
                    refresh={() => props.refresh()}
                    viewExperimentDetailsModal={showViewExperimentDetailsModal}
                    experimentMetadata={experimentMetadata}
                    experimentData={experimentData}
                    viewExperimentDetailsModalClose={() => handleViewExperimentDetailsModalClose()}
                />

                <ViewExperimentMetadata
                    refresh={() => props.refresh()}
                    viewExperimentMetadataModal={showViewExperimentMetadataModal}
                    experimentMetadata={experimentMetadata}
                    viewExperimentMetadataModalClose={() => handleViewExperimentMetadataModalClose()}
                />

            </>
        )
    }

    // render
    const { classes } = props;
    return (
        <>
            {getExperimentMetadata()}
        </>
    );

}

ExperimentList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withLoader(withResponseHandler(withStyles(ExperimentList, useStyles)))