import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  Container,
  Typography,
  Button,
  Dialog,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import axios from "axios";
import DropBox from "./DropBox";
import { useSnackbar } from "notistack";
import EventDataInput from "./EventDataInput";

const useStyles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  appBar: {
    position: "relative",
  },
  stepContent: {
    padding: theme.spacing(3),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Steps = [
  {
    heading: "Set Event Details",
    buttonName: "Update",
  },
  {
    heading: "Upload Event Files (Main)",
    buttonName: "Upload",
  },
  {
    heading: "Upload Event File (Background)",
    buttonName: "Upload",
  },
];

const EventEditor = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleEventDataChange = (id, value, serverType) => {
    var set = require("lodash.setwith");
    let event = Object.assign({}, props.event);
    if (serverType == "array") {
      set(event.EventData, id, [value], Object);
    } else {
      set(event.EventData, id, value, Object);
    }
    props.updateEvent(event);
  };

  const addTreasureReward = (add) => {
    let event = Object.assign({}, props.event);
    if (add == 1) {
      let treasureReward = {};
      treasureReward.Type = 0;
      treasureReward.Count = 0;
      treasureReward.SubCategory = 0;
      treasureReward.Tile = 1;
      event.EventData.TreasureRoadRewards.push(treasureReward);
    } else {
      event.EventData.TreasureRoadRewards.pop();
    }
    props.updateEvent(event);
  };

  const getStepContent = (index) => {
    switch (index) {
      case 1:
        return (
          <DropBox
            type="main"
            uploadedFiles={props.event.MainFiles}
            handleDropFile={handleDropFile}
            handleDeleteFile={removeFile}
          />
        );
      case 2:
        return (
          <DropBox
            type="background"
            handleDropFile={handleDropFile}
            uploadedFiles={props.event.BackgroundFiles}
            handleDeleteFile={removeFile}
          />
        );
      case 0:
        return (
          <EventDataInput
            loading={loading}
            eventType={props.event.EventType}
            handleEventDataChange={handleEventDataChange}
            addTreasureReward={addTreasureReward}
            eventData={props.event.EventData}
            setEventDetails={setEventDetails}
          />
        );
      default:
        return <div />;
    }
  };

  const uploadFile = (file, type) => {
    const data = new FormData();
    data.append("file", file);
    let url = "/api/file/fileType/" + type + "/eventID/" + props.event.ID;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          props.addFile(props.event.ID, type, data);
        }
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
        });
      });
  };

  const removeFile = (fileName, type) => {
    const data = new FormData();
    let url = "/api/removeFile/fileName/" + fileName + "/fileType/" + type + "/eventID/" + props.event.ID;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + fileName + " not deleted.", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + fileName + " deleted.", {
            variant: "success",
          });
          props.removeFile(props.event.ID, type, fileName);
        }
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + fileName + " not deleted. " + err, {
          variant: "error",
        });
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const setEventDetails = () => {
    setLoading(true);
    console.log(props.event.EventData);
    let url = "/api/updateEventDetails/eventID/" + props.event.ID;
    axios
      .post(url, props.event.EventData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        setLoading(false);
        if (data.err) {
          const key = enqueueSnackbar(
            "Event Details not updated: err: " + data.err,
            {
              variant: "error",
            },
          );
        } else {
          const key = enqueueSnackbar("Event Details updated", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        const key = enqueueSnackbar("Event Details not updated: err: " + err, {
          variant: "error",
          onClick: () => {
            closeSnackbar(key);
          },
        });
      });
  };

  const { classes } = props;

  if (!props.event) {
    return <div></div>;
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.event.Name}
          </Typography>
          <div style={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" className={classes.container}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {Steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step.heading}</StepLabel>
                <StepContent>
                  <div className={classes.stepContent}>
                    {getStepContent(index)}
                  </div>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    {activeStep === Steps.length - 1 ? (
                      ""
                    ) : (
                      <Button
                        disabled={
                          props.event.EventData.EventThemeInfo.DefaultAsset
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleNext(index);
                        }}
                        className={classes.button}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Container>
    </Dialog>
  );
};

EventEditor.propTypes = {
  children: PropTypes.node,
};

export default withStyles(EventEditor, useStyles);
