import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useCallback, useEffect, useMemo } from "react";

const RewardData = (props: any) => {
  let rewardType = props.rewardData[props.tier]["type"];

  const updateRewardData = (tier: string, data: string, event: any) => {
    props.setReward({
      ...props.rewardData,
      [tier]: {
        ...props.rewardData[tier],
        [data]: event.target.value,
      },
    });
  };

  const rewardPrefix = useMemo(() => {
    switch (rewardType) {
      case "TO":
        return "";
      case "BD":
        return "";
      case "D":
        return "";
      default:
        return "";
    }
  }, [rewardType]);

  const handleNameChange = (value: string) => {
    if (value.startsWith(rewardPrefix)) {
      value = value.slice(rewardPrefix.length);
    }

    const valueWithPrefix = (rewardPrefix + value).toUpperCase();

    updateRewardData(props.tier, "name", {
      target: { value: valueWithPrefix },
    });
  };

  useEffect(() => {
    return () => {
      updateRewardData(props.tier, "name", {
        target: { value: "" },
      });
    };
  }, []);

  function getNameValue(): string {
    const orgValue = (props.rewardData[props.tier]["name"] || "") as string;

    if (orgValue.startsWith(rewardPrefix)) {
      return orgValue.slice(rewardPrefix.length);
    }

    return orgValue;
  }

  const validateAvatarID = () => {
    const avatarID = props.rewardData[props.tier]["ID"];

    if (isNaN(avatarID) || avatarID.trim() === "") {
      return "Avatar ID must be a valid number.";
    }

    return null;
  };

  const validateDiceID = () => {
    const diceID = props.rewardData[props.tier]["ID"];

    if (isNaN(diceID) || diceID.trim() === "") {
      return "Dice ID must be a valid number.";
    }

    return null;
  };

  const validateFrameID = () => {
    const frameID = props.rewardData[props.tier]["ID"];

    if (isNaN(frameID) || frameID.trim() === "") {
      return "Frame ID must be a valid number.";
    }

    return null;
  };

  const validateTokenID = () => {
    const tokenID = props.rewardData[props.tier]["ID"];

    if (isNaN(tokenID) || tokenID.trim() === "") {
      return "Token ID must be a valid number.";
    }

    return null;
  };

  const avatarIDValidationMessage = validateAvatarID();
  const diceIDValidationMessage = validateDiceID();
  const frameIDValidationMessage = validateFrameID();
  const tokenIDValidationMessage = validateTokenID();

  switch (rewardType) {
    case "TO":
      return (
        <>
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Token ID"
              value={props.rewardData[props.tier]["ID"]}
              onChange={(event) => {
                updateRewardData(props.tier, "ID", event);
              }}
              variant="outlined"
            />
          </form>
          {tokenIDValidationMessage && (
            <p style={{ color: "red", marginTop: "0.5rem" }}>
              {tokenIDValidationMessage}
            </p>
          )}
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Token Name"
              value={getNameValue()}
              onInput={(ev: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(ev.target.value)
              }
              variant="outlined"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start" style={{ marginRight: 0 }}>
              //       TOKEN_
              //     </InputAdornment>
              //   ),
              // }}
            />
          </form>
        </>
      );
    case "AV":
      return (
        <>
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Avatar ID"
              value={props.rewardData[props.tier]["ID"]}
              onChange={(event) => {
                updateRewardData(props.tier, "ID", event);
              }}
              variant="outlined"
            />
          </form>
          {avatarIDValidationMessage && (
            <p style={{ color: "red", marginTop: "0.5rem" }}>
              {avatarIDValidationMessage}
            </p>
          )}
        </>
      );
    case "BD":
      return (
        <>
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Board Name"
              value={getNameValue()}
              onInput={(ev: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(ev.target.value)
              }
              variant="outlined"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start" style={{ marginRight: 0 }}>
              //       BOARD_
              //     </InputAdornment>
              //   ),
              // }}
            />
          </form>
        </>
      );
    case "F":
      return (
        <>
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Frame ID"
              value={props.rewardData[props.tier]["ID"]}
              onChange={(event) => {
                updateRewardData(props.tier, "ID", event);
              }}
              variant="outlined"
            />
          </form>
          {frameIDValidationMessage && (
            <p style={{ color: "red", marginTop: "0.5rem" }}>
              {frameIDValidationMessage}
            </p>
          )}
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Frame Name"
              value={getNameValue()}
              onInput={(ev: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(ev.target.value)
              }
              variant="outlined"
            />
          </form>
        </>
      );
    case "D":
      return (
        <>
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Dice ID"
              value={props.rewardData[props.tier]["ID"]}
              onChange={(event) => {
                updateRewardData(props.tier, "ID", event);
              }}
              variant="outlined"
            />
          </form>
          {diceIDValidationMessage && (
            <p style={{ color: "red", marginTop: "0.5rem" }}>
              {diceIDValidationMessage}
            </p>
          )}
          <form noValidate autoComplete="off" style={{ marginTop: "2vh" }}>
            <TextField
              id="outlined-basic"
              label="Dice Name"
              value={getNameValue()}
              onInput={(ev: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(ev.target.value)
              }
              variant="outlined"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start" style={{ marginRight: 0 }}>
              //       DICE_
              //     </InputAdornment>
              //   ),
              // }}
            />
          </form>
        </>
      );
    default:
      return;
  }
};

const RewardDropDown = (props: any) => {
  const setRewardType = (tier: any, event: any) => {
    props.setReward({
      ...props.rewardData,
      [tier]: {
        ...props.rewardData[tier],
        type: event.target.value,
      },
    });
  };

  return (
    <div style={{ width: "100%", margin: "2vh 0" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <h3>{props.header}</h3>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <FormControl variant="outlined" style={{ width: "80%" }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="type-select"
              id="type-select"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={props.rewardData[props.tier]["type"]}
              onChange={(event) => {
                setRewardType(props.tier, event);
              }}
            >
              <MenuItem value="TO">Token</MenuItem>
              <MenuItem value="D">Dice</MenuItem>
              <MenuItem value="AV">Avatar</MenuItem>
              <MenuItem value="BD">Board</MenuItem>
              <MenuItem value="F">Frame</MenuItem>
            </Select>
          </FormControl>

          <RewardData {...props} />
        </div>
      </div>
    </div>
  );
};

export default RewardDropDown;
