// This function is a HOC. We shall use this class to wrap around any component where we want to display a loader.

import { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const withLoader = (WrappedComponent) => {
    return (props) => {
        const [loading, setLoading] = useState(false);

        const showLoader = () => {
            setLoading(true);
        }

        const hideLoader = () => {
            setLoading(false);
        }

        return (
            <>
                <div style={{
                    pointerEvents: loading ? 'none' : 'auto',
                }}>
                    <WrappedComponent
                        {...props}
                        showLoader={() => showLoader()}
                        hideLoader={() => hideLoader()}
                    />
                </div>

                {loading && (
                    <div style={{
                        position: 'fixed', // Overlay fixed
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1501 // Make sure this is unchanged. This zIndex is above every component in material UI
                    }} >
                        <Backdrop
                            sx={{ color: '#fff' }}
                            open={loading}
                        >
                            <CircularProgress size={40} />
                        </Backdrop>
                    </div>
                )}
            </>
        )
    }
}

export default withLoader
