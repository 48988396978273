import React, { useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import axios from "axios";
import withResponseHandler from "../../../components/ResponseHandler";
import { CountryList } from "../../../constants/constants";
import { validateExperimentId, validateUserId } from "../../validators/ABExperimentationValidators";

const useStyles = (theme) => ({
    textField: {
        padding: theme.spacing(2),
    },
    countryDropDown: {
        margin: theme.spacing(2),
        minWidth: 150,
    },
    submitButton: {
        padding: theme.spacing(2),
    },
    resultBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: '#383838',
    },
});

const GetUserCohort = (props) => {

    // hooks
    const [experimentId, setExperimentId] = useState("")
    const [experimentIdError, setExperimentIdError] = useState(false)
    const [experimentIdHelperText, setExperimentIdHelperText] = useState("")

    const [userId, setUserId] = useState("")
    const [userIdError, setUserIdError] = useState(false)
    const [userIdHelperText, setUserIdHelperText] = useState("")

    const [lastLoggedIn, setLastLoggedIn] = useState("")
    const [result, setResult] = useState("-")

    const [versionId, setVersionId] = useState("")
    const [versionIdHelperText, setVersionIdHelperText] = useState("")

    const [country, setCountry] = useState("")

    // event handlers

    const handleVersionIdChange = (event) => {
        if (event.target.value === "") {
            setVersionIdHelperText("")
        } else {
            setVersionIdHelperText("This overrides the login time and does not take session into account")
        }
        setVersionId(event.target.value)
    }

    const handleExperimentIdChange = (event) => {
        let validate = validateExperimentId(event.target.value)
        if (validate[0]) {
            setExperimentIdError(false)
            setExperimentIdHelperText("")
        } else {
            setExperimentIdError(true)
            setExperimentIdHelperText(validate[1])
        }
        setExperimentId(event.target.value)
    }

    const handleUserIdChange = (event) => {
        let validate = validateUserId(event.target.value)
        if (validate[0]) {
            setUserIdError(false)
            setUserIdHelperText("")
        } else {
            setUserIdError(true)
            setUserIdHelperText(validate[1])
        }
        setUserId(event.target.value)
    }

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    }

    const handleLastLoggedInChange = (event) => {
        setLastLoggedIn(event.target.value)
    }

    const handleSuccess = (data) => {
        setResult(data.data.cohort)
        props.handleSuccess(data)
    }

    const handleError = (error) => {
        props.handleError(error)
    }

    // collect data and send to server
    const handleSubmit = () => {

        if (experimentIdError || userIdError) {
            alert("Please fix the errors")
            return
        }

        let data = {
            expId: experimentId,
            userId: userId,
            loginTime: lastLoggedIn + ":00.000Z",
            country: country,
        }

        let url = "/api/experiment/cohort"

        axios.get(url, { params: data })
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                handleSuccess(data);
            })
            .catch((error) => {
                handleError(error);
            })

    }

    // utilities
    const getLastLoggedIn = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    label="Start Time in UTC"
                    type="datetime-local"
                    value={lastLoggedIn}
                    onChange={(event) => handleLastLoggedInChange(event)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        )
    }

    const getVersionId = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    id="version-id"
                    label="Version ID"
                    value={versionId}
                    variant="outlined"
                    onChange={(event) => handleVersionIdChange(event)}
                    helperText={versionIdHelperText}
                />
            </div>
        )
    }

    const getExperimentIdBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    id="experiment-id"
                    label="Experiment ID"
                    value={experimentId}
                    onChange={(event) => handleExperimentIdChange(event)}
                    fullWidth
                    variant="outlined"
                    error={experimentIdError}
                    helperText={experimentIdHelperText}
                />
            </div>
        )
    }

    const getUserIdBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    fullWidth
                    id="user-id"
                    label="User ID"
                    defaultValue="User ID"
                    value={userId}
                    variant="outlined"
                    onChange={(event) => handleUserIdChange(event)}
                    error={userIdError}
                    helperText={userIdHelperText}
                />
            </div>
        )
    }

    const getCountryDropdown = () => {
        return (
            <div>
                <FormControl className={classes.countryDropDown}>
                    <InputLabel id="simple-select-label">Country</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="demo-simple-select"
                        value={country}
                        label="Country"
                        onChange={(event) => handleCountryChange(event)}
                    >
                        {
                            CountryList.map((key) => {
                                return (
                                    <MenuItem value={key[0]}>{key[1]}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }

    const getSubmitButton = () => {
        return (
            <div className={classes.submitButton}>
                <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            </div>
        )
    }

    const getResultBox = () => {
        return (
            <div className={classes.resultBox}>
                <Typography variant="h1">
                    {result}
                </Typography>
            </div>
        )
    }

    // return
    const { classes } = props;
    return (
        <>
            <Grid container>
                <Grid item xs={7}>
                    {/* Input for experiment ID */}
                    {getExperimentIdBox()}

                    {/* Input for user ID */}
                    {getUserIdBox()}

                    {/* Input for last logged in */}
                    {getLastLoggedIn()}

                    {/* Input for country */}
                    {getCountryDropdown()}

                    {/* Submit button */}
                    {getSubmitButton()}
                </Grid>

                <Grid item xs={5}>
                    {/* Result box */}
                    {getResultBox()}
                </Grid>
            </Grid>
        </>
    )
}

GetUserCohort.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withResponseHandler(withStyles(GetUserCohort, useStyles));
