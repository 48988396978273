import { React, useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import dayjs from "dayjs";
import {
  IconButton,
  Container,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import AddCircle from "@mui/icons-material/AddCircle";
import AssignAssetPopup from "../../../components/club/leaderboard/rewardScheduler/AssignAssetPopup";
import CollapsableCard from "../../../components/club/leaderboard/rewardScheduler/CollapsableCard";
import { getUserEmail } from "../../../utils/tokenUtils";
import axios from "axios";
import utc from "dayjs/plugin/utc";
import CustomDatePicker from "../../../components/shared/CustomDatePicker";

dayjs.extend(utc);

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  checkBoxPaper: {
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 18,
  },
  datePicker: {
    marginLeft: 10,
  },
  loaderWrapper: {
    display: "flex",
    marginTop: theme.spacing(10),
    justifyContent: "center",
  },
});

const ClubLeaderboardReward = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = useState(false);
  const [clubAssetDraftList, setClubAssetDraftList] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    if (!isFetched) fetchClubRewards();
  }, []);

  const tomorrow = dayjs().add(1, "day").utc();

  const nonMondays = (date) => {
    return date.day() != 1;
  };

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addClubRewardScheduleDraft = async (props) => {
    const userEmail = getUserEmail();
    var clubdata = {
      assetId: props.assetId,
      assetCategory: props.assetCategory,
      assetCategoryId: props.assetCategoryId,
      assetType: props.assetType,
      assetTypeId: props.assetTypeId,
      minRank: props.minRank,
      maxRank: props.maxRank,
      userEmail: userEmail,
    };

    const url = "/api/club/leaderboard/rewardDraft";

    await axios
      .post(url, JSON.stringify(clubdata))
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        const id = data.id;
        clubdata["id"] = id;
        enqueueSnackbar(data.message, {
          variant: "success",
        });
        setClubAssetDraftList((clubAssetDraftList) => ({
          ...clubAssetDraftList,
          [id]: clubdata,
        }));
      })
      .catch((e) => {
        if (e.response?.data) {
          enqueueSnackbar(e.response.data, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      });
  };

  const removeClubRewardScheduleDraft = async (id) => {
    const url = "/api/club/leaderboard/rewardDraft";
    const params = { id: id };
    await axios
      .delete(url, {
        params: params,
      })
      .then((_) => {
        delete clubAssetDraftList[id];
        let updatedList = { ...clubAssetDraftList };
        setClubAssetDraftList(updatedList);
        enqueueSnackbar("Deleted Successfully!", {
          variant: "default",
        });
      })
      .catch((e) => {
        if (e.response?.data) {
          enqueueSnackbar(e.response.data, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      });
  };

  const fetchClubRewards = async () => {
    const url = "/api/club/leaderboard/rewardDraft";
    await axios
      .get(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        let updatedList = {};
        data?.map((reward) => {
          updatedList[reward.id] = {
            id: reward.id,
            assetId: reward.assetId,
            assetCategory: reward.assetCategory,
            assetType: reward.assetType,
            assetTypeId: reward.assetTypeId,
            assetCategoryId: reward.assetCategoryId,
            minRank: reward.minRank,
            maxRank: reward.maxRank,
            userEmail: reward.userEmail,
          };
        });
        setClubAssetDraftList(updatedList);
        setIsFetched(true);
      })
      .catch((e) => {
        if (e.response?.data) {
          enqueueSnackbar(e.response.data, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      });
  };

  const publishLeaderboardRewardDraft = () => {
    if (startDate == undefined || endDate == undefined) {
      enqueueSnackbar("Rewards cant schedule without a DATE", {
        variant: "error",
      });
      return;
    }

    if (Object.keys(clubAssetDraftList).length == 0) {
      enqueueSnackbar("Reward list cant be empty", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    const url = `/api/club/leaderboard/publishRewards`;
    const payload = {
      startDate: startDate,
      endDate: endDate,
      assets: clubAssetDraftList,
    };

    axios
      .post(url, payload, {
        params: {
          dev: currentServer,
        },
      })
      .then((json) => {
        return json.data;
      })
      .then((_) => {
        enqueueSnackbar("Rewards Publish Successful", {
          variant: "success",
        });
        setLoading(false);
        setClubAssetDraftList({});
      })
      .catch((e) => {
        if (e.response?.data) {
          enqueueSnackbar(e.response.data, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      });
  };

  const _getHeaderFunctionalities = () => {
    return (
      <div className={classes.header}>
        <CustomDatePicker
          label="Start Date"
          value={startDate}
          onChange={(selectedDate) => {
            setStartDate(selectedDate);
          }}
          shouldDisableDate={nonMondays}
          format="DD/MM/YYYY"
          minDate={tomorrow}
          timezone="UTC"
        />
        <CustomDatePicker
          label="End Date"
          className={classes.datePicker}
          value={endDate}
          onChange={(selectedDate) => {
            setEndDate(selectedDate);
          }}
          format="DD/MM/YYYY"
          disabled={startDate == undefined}
          minDate={startDate ? dayjs(startDate).add(7, "day").utc() : undefined}
          shouldDisableDate={nonMondays}
          timezone="UTC"
        />

        <div style={{ flexGrow: 1 }}></div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="server-select"
            id="server-select"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={currentServer}
            onChange={handleServerChange}
          >
            <MenuItem value={0}>Dev</MenuItem>
            <MenuItem value={1}>Production</MenuItem>
          </Select>
        </FormControl>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant="outlined"
          size="large"
          onClick={publishLeaderboardRewardDraft}
          disabled={loading}
        >
          Publish
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    );
  };

  const { classes } = props;
  return (
    <div>
      {_getHeaderFunctionalities()}
      <Container className={classes.container} maxWidth="sm">
        <div className={classes.header}>
          <Typography color="textSecondary" variant="h5">
            Drafts
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            onClick={() => {
              handleClickOpen();
            }}
          >
            <AddCircle color="primary" fontSize="large" />
          </IconButton>
        </div>
        {isFetched ? (
          <div>
            {Object.keys(clubAssetDraftList).map((key) => (
              <CollapsableCard
                key={clubAssetDraftList[key].id}
                id={clubAssetDraftList[key].id}
                assetCategory={clubAssetDraftList[key].assetCategory}
                assetType={clubAssetDraftList[key].assetType}
                assetId={clubAssetDraftList[key].assetId}
                minRank={clubAssetDraftList[key].minRank}
                maxRank={clubAssetDraftList[key].maxRank}
                userEmail={clubAssetDraftList[key].userEmail}
                removeClubRewardScheduleDraft={removeClubRewardScheduleDraft}
              />
            ))}
          </div>
        ) : (
          <div className={classes.loaderWrapper}>
            <CircularProgress />
          </div>
        )}
      </Container>
      <AssignAssetPopup
        open={open}
        heading="Assign Assets"
        label="Asset ID"
        handleAccept={addClubRewardScheduleDraft}
        handleClose={() => setOpen(false)}
      ></AssignAssetPopup>
    </div>
  );
};

export default withStyles(ClubLeaderboardReward, useStyles);
