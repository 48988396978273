import React, { useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  createTheme,
  ThemeProvider,
  Paper,
  Checkbox,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import TokenUploader from "../components/TokenUploader";
import InfoIcon from "@mui/icons-material/Info";
import ConfirmDialog from "../components/ConfirmDialog";

const theme = createTheme({
  typography: {
    toolTipHeader: {
      color: "orange",
    },
    toolTipMessage: {
      color: "white",
    },
  },
});

function getToolTipLine(left, right) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="toolTipHeader">{left}</Typography>
        <Typography variant="toolTipMessage">{right}</Typography>
        <br></br>
      </ThemeProvider>
    </div>
  );
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  checkBoxPaper: {
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 18,
  },
});

const TokenUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isReUpload, setIsReUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [tokenType, setTokenType] = useState(0);
  const [addTokenEnabled, setAddTokenEnabled] = useState(false);
  const [tokenUploaders, setTokenUploaders] = useState([]);
  const [TokenInfos, setTokenInfos] = useState([]);

  const requiresConfirmDialog = useRef(false);
  const hasConfirmed = useRef(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  const handleOpenConfirmDialog = (msg) => {
    setConfirmationMsg(msg);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = (confirmed) => {
    setOpenConfirmDialog(false);
    if (confirmed) {
      hasConfirmed.current = true;
      publishTokenWithConfirmation();
    }
  };

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const setEnableAddToken = (isEnabled) => {
    setAddTokenEnabled(isEnabled);
  };

  const handleCheckerChange = () => {
    let confirm = true;
    if (!isReUpload) {
      confirm = window.confirm("Are you sure you want to reupload this token!");
    }
    if (confirm) {
      setIsReUpload(!isReUpload);
    }
  };

  const addTokenInfos = (tokenInfo) => {
    let newTokenInfo = true;
    if (TokenInfos.length > 0) {
      TokenInfos.map((v, i) => {
        if (tokenInfo.TokenID == v.TokenID) {
          newTokenInfo = false;
        }
      });
    }

    if (newTokenInfo) {
      let tokenInfos = TokenInfos;
      tokenInfos.push(tokenInfo);
      setTokenInfos(tokenInfos);
    }
  };

  const publishToken = () => {
    if (requiresConfirmDialog.current && !hasConfirmed.current) {
      handleOpenConfirmDialog(confirmationMsg);
      return;
    }

    publishTokenWithConfirmation();
  };

  const publishTokenWithConfirmation = () => {
    if (!addTokenEnabled) {
      const key = enqueueSnackbar("Enter all details", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    let url = "/api/uploadToken/dev/" + currentServer;
    axios
      .post(url, TokenInfos, {
        params: {
          isReUpload: isReUpload,
          hasConfirmed: hasConfirmed.current,
        },
      })
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Token Publish Failed! " + data.err, {
            variant: "error",
          });
        } else if (data.confirmation) {
          let confirmationMsg = data.confirmation;
          requiresConfirmDialog.current = true;
          handleOpenConfirmDialog(confirmationMsg);
        } else {
          const key = enqueueSnackbar("Token Publish Successful", {
            variant: "success",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Token Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const addTokens = () => {
    setTokenUploaders((current) => [
      ...current,
      <TokenUploader
        props={props}
        addTokenInfo={addTokenInfos}
        setEnableAddToken={setEnableAddToken}
        tokenID={props.tokenID}
      />,
    ]);
    setAddTokenEnabled(false);
  };

  const getTokenUploaders = () => {
    var tokenUploader = [];

    if (tokenUploaders.length > 0) {
      tokenUploader = tokenUploaders;
    } else {
      let tokenUploaderObj = (
        <TokenUploader
          props={props}
          addTokenInfo={addTokenInfos}
          setEnableAddToken={setEnableAddToken}
          tokenID={props.tokenID}
        />
      );
      tokenUploader.push(tokenUploaderObj);
      setTokenUploaders((current) => [...current, tokenUploaderObj]);
    }

    return tokenUploader;
  };

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Wrapper heading="Token Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={addTokens}
              disabled={!addTokenEnabled || loading}
            >
              Add Token
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Paper variant="outlined" className={classes.checkBoxPaper}>
              <Checkbox checked={isReUpload} onClick={handleCheckerChange} />
              <Typography variant="caption">Reupload Token</Typography>
            </Paper>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Token Upload File Info
                  </Typography>
                  {getToolTipLine(
                    "Filename:",
                    "	token_top_(colour)_(ID Number).png"
                  )}
                  {getToolTipLine(
                    "",
                    "Where color is one of red, green, blue, yellow"
                  )}
                  {getToolTipLine("Dimensions:", "	approx - 240x286")}
                  {getToolTipLine("Number:", "	4")}
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ fontSize: 40 }}></InfoIcon>
            </Tooltip>
            <div style={{ flexGrow: 1 }}></div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishToken}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <ConfirmDialog
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              dialogContentText={confirmationMsg}
            />
          </div>
          {getTokenUploaders()}
        </Container>
      </Wrapper>
    </div>
  );
};

TokenUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(TokenUpload, useStyles);
