import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { makeStyles, withStyles } from "tss-react/mui";
import { apiClient } from "../utils/apiClient";
import DropBox from "./DropBox";

const DICE_FILES_PREFIXES = [
  "dice0",
  "dice1",
  "dice2",
  "dice3",
  "dice4",
  "dice5",
  "dice6",
  "diceface",
  "diceroll1",
  "diceroll2",
  "diceroll3",
  "diceroll4",
  "diceroll5",
  "diceroll6",
  "dice_anim",
];

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DiceUploader = ({ serverEnv, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [commonFiles, setCommonFiles] = useState([]);
  const [spineHDFiles, setspineHDFiles] = useState([]);
  const [spineSDFiles, setspineSDFiles] = useState([]);
  const [spineLDFiles, setspineLDFiles] = useState([]);
  const [diceID, setDiceID] = useState(props.currEventDiceID);
  const [diceName, setDiceName] = useState("");
  const [diceAnimationType, setDiceAnimationType] = useState(0);
  const [diceType, setDiceType] = useState(0);
  const [basicDiceRarity, setDiceRarity] = useState(1);
  const [basicDiceRarityV2, setDiceRarityV2] = useState(20);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCommonFiles([]);
    setspineHDFiles([]);
    setspineLDFiles([]);
    setspineSDFiles([]);

    console.log({ serverEnv });
  }, [serverEnv]);

  async function uploadFile(file, type) {
    if (diceID == "") {
      const key = enqueueSnackbar("No Dice ID", {
        variant: "error",
      });
      return;
    }

    if (diceName == "") {
      const key = enqueueSnackbar("No Dice Name", {
        variant: "error",
      });
      return;
    }

    let found = false;
    for (const fileNamePrefix of DICE_FILES_PREFIXES) {
      if (file.name == fileNamePrefix + "_" + diceID + ".png") {
        found = true;
      }
    }

    if (diceAnimationType == 1 && isAnimatedDiceFile(file.name, diceID)) {
      found = true;
    }

    if (!found) {
      enqueueSnackbar("Upload a Valid File", {
        variant: "error",
      });
      return;
    }

    const formBody = new FormData();
    formBody.append("file", file);
    formBody.append("serverEnv", serverEnv);

    let url = "/api/file/diceID/" + diceID + "/type/" + type.toString();

    setLoading(true);
    try {
      const { data } = await apiClient.post(url, formBody);

      if (data.err) {
        throw data.err;
      } else {
        enqueueSnackbar("File - " + file.name + " uploaded. " + data.err, {
          variant: "success",
        });
        addFile(data, type);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("File - " + file.name + " not uploaded. " + err, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const addFile = (file, type) => {
    if (type == "main") {
      const currFiles = commonFiles;
      currFiles.push(file);
      setCommonFiles([...currFiles]);
    } else if (type == "hd") {
      const currFiles = spineHDFiles;
      currFiles.push(file);
      setspineHDFiles([...currFiles]);
    } else if (type == "sd") {
      const currFiles = spineSDFiles;
      currFiles.push(file);
      setspineSDFiles([...currFiles]);
    } else if (type == "ld") {
      const currFiles = spineLDFiles;
      currFiles.push(file);
      setspineLDFiles([...currFiles]);
    }
    checkAndEnableAddDice();
    updateDiceInfo();
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const checkAndEnableAddDice = () => {
    if (diceName != "" && diceID != "" && commonFiles.length > 0) {
      props.setEnableAddDice(true);
    } else {
      props.setEnableAddDice(false);
    }
  };

  const getCurrentDiceID = (event) => {
    if (event.target.value == 0) {
      return props.currEventDiceID;
    }
    if (event.target.value == 1) {
      return props.currCosmeticDiceID;
    }

    return props.currRewardBoxDiceID;
  };

  const updateDiceInfo = () => {
    let diceInfo = {
      DiceName: diceName,
      DiceID: diceID.toString(),
      DiceAnimationType: diceAnimationType.toString(),
      DiceType: diceType.toString(),
      BasicDiceRarity: basicDiceRarity.toString(),
      BasicDiceRarityV2: basicDiceRarityV2.toString(),
    };
    props.addDiceInfo(diceInfo);
  };

  const isAnimatedDiceFile = (fileName, diceID) => {
    const fileTypes = [".skel", ".atlas", ".png"];
    for (var fileType of fileTypes) {
      if (fileName == "dice_anim_" + diceID + fileType) {
        return true;
      }
    }
    if (
      fileName == "dice_anim_roll_outcome_" + diceID + ".mp3" ||
      fileName == "dice_anim_roll_outcome_6_" + diceID + ".mp3" ||
      fileName == "dice_anim_roll_" + diceID + ".mp3"
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Dice ID"
            value={diceID}
            onChange={(event) => {
              setDiceID(event.target.value);
              checkAndEnableAddDice();
            }}
            variant="outlined"
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Dice Name"
            value={diceName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: 0 }}>
                  _DICE
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setDiceName(event.target.value.toUpperCase());
              checkAndEnableAddDice();
            }}
            variant="outlined"
          />
        </form>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="dice-animation-type"
            id="dice-animation-type"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={diceAnimationType}
            onChange={(event) => {
              setDiceAnimationType(event.target.value);
              setDiceID(getCurrentDiceID(event));
              checkAndEnableAddDice();
            }}
          >
            <MenuItem value={0}>Normal</MenuItem>
            <MenuItem value={1}>Animated</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="dice-type"
            id="dice-type"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={diceType}
            onChange={(event) => {
              setDiceType(event.target.value);
              setDiceID(getCurrentDiceID(event));
              checkAndEnableAddDice();
            }}
          >
            <MenuItem value={0}>Event</MenuItem>
            <MenuItem value={1}>Cosmetic</MenuItem>
            <MenuItem value={2}>Reward Box Cosmetic</MenuItem>
            <MenuItem value={3}>Basic</MenuItem>
          </Select>
        </FormControl>
        {diceType == 3 && (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="rarity-select-label"
                id="rarity-select"
                inputProps={{ "aria-label": "Without label" }}
                value={basicDiceRarity}
                onChange={(event) => {
                  setDiceRarity(event.target.value);
                  setDiceID(getCurrentDiceID(event));
                  checkAndEnableAddDice();
                }}
              >
                <MenuItem value={1}>COMMON</MenuItem>
                <MenuItem value={2}>RARE</MenuItem>
                <MenuItem value={3}>EPIC</MenuItem>
                <MenuItem value={4}>LEGEND</MenuItem>
                <MenuItem value={18}>SOCIAL_DICE</MenuItem>
              </Select>
              <FormHelperText>Rarity(For Basic Dices Only)</FormHelperText>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="rarityv2-select-label"
                id="rarityv2-select"
                inputProps={{ "aria-label": "Without label" }}
                value={basicDiceRarityV2}
                onChange={(event) => {
                  setDiceRarityV2(event.target.value);
                  setDiceID(getCurrentDiceID(event));
                  checkAndEnableAddDice();
                }}
              >
                <MenuItem value={20}>WOOD</MenuItem>
                <MenuItem value={21}>BRONZE</MenuItem>
                <MenuItem value={22}>SILVER</MenuItem>
                <MenuItem value={23}>GOLD</MenuItem>
                <MenuItem value={24}>DIAMOND</MenuItem>
                <MenuItem value={25}>CRYSTAL</MenuItem>
                <MenuItem value={26}>TITAN</MenuItem>
              </Select>
              <FormHelperText>
                RarityV2(For Basic Dices Only, eligible for MUTB)
              </FormHelperText>
            </FormControl>
          </>
        )}
      </div>
      <h2>Common Assets </h2>
      <small style={{ color: "#32CD32" }}>
        Animated: ["dice_anim_{}.skel", "dice_anim_roll_{}.mp3",
        "dice_anim_roll_outcome_{}.mp3", "dice_anim_roll_outcome_6_{}.mp3"]
        <br />
        Non-Animated: ["dice0_{}.png", "dice1_{}.png", "dice2_{}.png", "dice3_{}
        .png", "dice4_{}.png", "dice5_{}.png", "dice6_{}.png", "diceroll1_{}
        .png", "diceroll2_{}.png", "diceroll3_{}.png"]
      </small>
      <DropBox
        type="main"
        uploadedFiles={commonFiles}
        handleDropFile={handleDropFile}
      />
      {diceAnimationType == 1 && (
        <>
          <br />
          <br />
          <br />
          <div>
            <small style={{ color: "#32CD32" }}>
              Animated For Each Section: ["dice_anim_{}.atlas", "dice_anim_{}
              .png"]
            </small>
            <br />
            <small style={{ color: "red" }}>
              PS: Please upload static dice separately first (Ignore if already
              done)
            </small>
          </div>

          <Grid container spacing={2} alignItems="center">
            <h2>Animated dice Spine HD Assets: </h2>
            <DropBox
              type="hd"
              uploadedFiles={spineHDFiles}
              handleDropFile={handleDropFile}
            />
            <h2>Animated dice Spine LD Assets: </h2>
            <DropBox
              type="ld"
              uploadedFiles={spineLDFiles}
              handleDropFile={handleDropFile}
            />
            <h2>Animated dice Spine SD Assets: </h2>
            <DropBox
              type="sd"
              uploadedFiles={spineSDFiles}
              handleDropFile={handleDropFile}
            />
          </Grid>
        </>
      )}
    </Container>
  );
};
export default withStyles(DiceUploader, useStyles);
