import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  InputLabel,
} from "@mui/material";
import DropBox from "../../DropBox";
import { useSnackbar } from "notistack";
import ColorInput from "./ColorInput";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    color: "#000",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ClubCosmeticAssetUploader = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [files, setFiles] = useState(props.assetInfo.files)
  const [hdFiles, setHdFiles] = useState(props.assetInfo.hdFiles)
  const [sdFiles, setSdFiles] = useState(props.assetInfo.sdFiles)
  const [ldFiles, setLdFiles] = useState(props.assetInfo.ldFiles)
  const [thumbnailFiles, setThumbnailFiles] = useState(props.assetInfo.thumbnailFiles)
  const [chatbgFiles, setChatbgFiles] = useState(props.assetInfo.chatbgFiles)
  const [assetName, setAssetName] = useState(props.assetInfo.assetName)
  const [assetID, setAssetID] = useState(props.assetInfo.assetID)
  const [assetType, setAssetType] = useState(props.assetInfo.assetType)
  const [collectionType, setCollectionType] = useState(props.assetInfo.collectionType)
  const [uploadType, setUploadType] = useState(props.assetInfo.uploadType)
  const [textColor, setTextColor] = useState(props.assetInfo.textColor)
  const [borderColor, setBorderColor] = useState(props.assetInfo.borderColor)

  const uploadFile = (file, type) => {
    if (assetID == "") {
      const key = enqueueSnackbar("No Asset ID", {
        variant: "error",
      });
      return;
    }

    if (assetName == "") {
      const key = enqueueSnackbar("No Asset Name", {
        variant: "error",
      });
      return;
    }

    addFile(file, type);
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadFile(file, type);
    }
  };

  const addFile = (file, type) => {
    if (type == "0") {
      setFiles([file]);
    } else if (type == "1") {
      setHdFiles((current) => [...current, file]);
    } else if (type == "2") {
      setSdFiles((current) => [...current, file]);
    } else if (type == "3") {
      setLdFiles((current) => [...current, file]);
    } else if (type == "4") {
      setThumbnailFiles([file]);
    } else if (type == "5") {
      setChatbgFiles([file]);
    }
  };

  useEffect(() => {
    let assetInfo = {
      id: props.id, assetName: assetName, assetID: assetID, assetType: assetType,
      collectionType: collectionType, uploadType: uploadType, textColor: textColor,
      borderColor: borderColor, files: files, hdFiles: hdFiles,
      sdFiles: sdFiles, ldFiles: ldFiles, thumbnailFiles: thumbnailFiles, chatbgFiles: chatbgFiles,
    }
    props.setAssetInfo(assetInfo)
  })

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.root}
          style={{ marginRight: 10 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Asset ID"
            value={assetID}
            onChange={(event) => {
              setAssetID(event.target.value);
            }}
            variant="outlined"
            error={props.validating && assetID == ""}
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label="Asset Name"
            value={assetName}
            onChange={(event) => {
              setAssetName(event.target.value);
            }}
            variant="outlined"
            error={props.validating && assetName == ""}
          />
        </form>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="asset-type"
            id="asset-type"
            inputProps={{ "aria-label": "Without label" }}
            value={assetType}
            onChange={(event) => {
              setAssetType(event.target.value);
            }}
            defaultValue={"backgrounds"}
            error={props.validating && assetType == ""}
          >
            <MenuItem value={"backgrounds"}>Background</MenuItem>
            <MenuItem value={"cards"}>Card Frame</MenuItem>
            <MenuItem value={"profileFrames"}>Profile Frame</MenuItem>
            <MenuItem value={"chatBubbles"}>Chat Bubble</MenuItem>
            <MenuItem value={"entryEffects"}>Entry Effect</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="collection-type"
            id="asset-type"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={collectionType}
            onChange={(event) => {
              setCollectionType(event.target.value)
            }}
            defaultValue="LEADERBOARD"
            error={props.validating && collectionType == ""}
          >
            <MenuItem value={"LEADERBOARD"}>LEADERBOARD</MenuItem>
            <MenuItem value={"PROGRESSION"}>PROGRESSION</MenuItem>
            <MenuItem value={"CUSTOM_REWARDS"}>CUSTOM_REWARDS</MenuItem>
          </Select>
        </FormControl>
        {(assetType == "backgrounds" ||
          assetType == "entryEffects") && (
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="upload-type"
                id="upload-type"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={uploadType}
                onChange={(event) => {
                  setUploadType(event.target.value)
                }}
                error={props.validating && uploadType == -1}
              >
                <MenuItem value={0}>Non Animated</MenuItem>
                <MenuItem value={1}>Animated</MenuItem>
              </Select>
            </FormControl>
          )}
      </div >
      <div>
        {uploadType == 0 &&
          assetType != "backgrounds" &&
          assetType != "entryEffects" && (
            <div style={(() => {
              if (props.validating && files.length == 0) {
                return { border: '2px solid red' }
              } else {
                return {}
              }
            })()}>
              <DropBox
                type="0"
                uploadedFiles={files.map(item => item.name)}
                handleDropFile={handleDropFile}
              />
            </div>
          )}
        <Grid container spacing={2} alignItems="center">
          {uploadType == 0 &&
            (assetType == "backgrounds" ||
              assetType == "entryEffects") && (
              <>
                <Grid item xs={12}>
                  <InputLabel id="label">
                    File
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div style={(() => {
                    if (props.validating && files.length == 0) {
                      return { border: '2px solid red' }
                    } else {
                      return {}
                    }
                  })()}>
                    <DropBox
                      type="0"
                      uploadedFiles={files.map(item => item.name)}
                      handleDropFile={handleDropFile}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="label">Thumbnail file</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div style={(() => {
                    if (props.validating && thumbnailFiles.length == 0) {
                      return { border: '2px solid red' }
                    } else {
                      return {}
                    }
                  })()}>
                    <DropBox
                      type="4"
                      uploadedFiles={thumbnailFiles.map(item => item.name)}
                      handleDropFile={handleDropFile}
                    />
                  </div>
                </Grid >
              </>
            )}
          {
            uploadType == 1 && (
              <>
                <Grid item xs={12}>
                  <InputLabel id="label">HD files</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div style={(() => {
                    if (props.validating && hdFiles.length != 3) {
                      return { border: '2px solid red' }
                    } else {
                      return {}
                    }
                  })()}>
                    <DropBox
                      type="1"
                      uploadedFiles={hdFiles.map(item => item.name)}
                      handleDropFile={handleDropFile}
                    />
                  </div>
                </Grid >
                <Grid item xs={12}>
                  <InputLabel id="label">SD files</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div style={(() => {
                    if (props.validating && sdFiles.length != 3) {
                      return { border: '2px solid red' }
                    } else {
                      return {}
                    }
                  })()}>
                    <DropBox
                      type="2"
                      uploadedFiles={sdFiles.map(item => item.name)}
                      handleDropFile={handleDropFile}
                    />
                  </div>
                </Grid >
                <Grid item xs={12}>
                  <InputLabel id="label">LD files</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div style={(() => {
                    if (props.validating && ldFiles.length != 3) {
                      return { border: '2px solid red' }
                    } else {
                      return {}
                    }
                  })()}>
                    <DropBox
                      type="3"
                      uploadedFiles={ldFiles.map(item => item.name)}
                      handleDropFile={handleDropFile}
                    />
                  </div>
                </Grid >
                <Grid item xs={12}>
                  <InputLabel id="label">
                    Thumbnail file
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div style={(() => {
                    if (props.validating && thumbnailFiles.length == 0) {
                      return { border: '2px solid red' }
                    } else {
                      return {}
                    }
                  })()}>
                    <DropBox
                      type="4"
                      uploadedFiles={thumbnailFiles.map(item => item.name)}
                      handleDropFile={handleDropFile}
                    />
                  </div>
                </Grid>
                {
                  assetType == "entryEffects" && (
                    <>
                      <Grid item xs={12}>
                        <InputLabel id="label">Chatbg file</InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={(() => {
                          if (props.validating && chatbgFiles.length == 0) {
                            return { border: '2px solid red' }
                          } else {
                            return {}
                          }
                        })()}>
                          <DropBox
                            type="5"
                            uploadedFiles={chatbgFiles.map(item => item.name)}
                            handleDropFile={handleDropFile}
                          />
                        </div>
                      </Grid >
                      <Grid item xs={12}>
                        <ColorInput
                          label="Border Color"
                          color={borderColor}
                          setColor={setBorderColor}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ColorInput
                          label="Text Color"
                          color={textColor}
                          setColor={setTextColor}
                        />
                      </Grid>
                    </>
                  )
                }
              </>
            )
          }
        </Grid >
      </div >
    </Container >
  );
};

export default ClubCosmeticAssetUploader;
