import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import withResponseHandler from "./ResponseHandler";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons, GridOverlay,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = (theme) => ({
    tableTitle: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#8FC9F9'
    },
    updateButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    dataGridBox: {
        minHeight: '500px',
        maxHeight: 'auto',
    }
})

const ABTable = (props) => {
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [isEditable, setIsEditable] = useState(false)
    const [rowModesModel, setRowModesModel] = useState({})
    const [tableTitle, setTableTitle] = useState("")

    useEffect(() => {
        setRows(getRowsForTable())
        setIsEditable(props.isEditable)
        setTableTitle(props.tableTitle)
    }, [props.rows, props.columns, props.isEditable, props.tableTitle])

    useEffect(() => {
        const rowModesModel = getRowModesModel();
        setRowModesModel(rowModesModel)
    }, [rows])

    useEffect(() => {
        setColumns(getColumnsForTable())
    }, [rowModesModel])


    const handleUpdateButton = () => {
        props.callbackWithUpdatedData(rows)
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    }

    let isDoubleClicked = false
    const handleRowClick = (params, event) => {
        isDoubleClicked = false
        setTimeout(() => {
            if (!isDoubleClicked) {
                if (props.callbackForRowClick) {
                    props.callbackForRowClick(params.row)
                }
            }
        }, 300)
    }

    const handleRowDoubleClick = (params, event) => {
        isDoubleClicked = true
        if (props.callbackForRowDoubleClick) {
            props.callbackForRowDoubleClick(params.row)
        }
    }

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    }

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    }

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    }

    const handleEditToolBarClick = () => {
        const id = randomId()
        setRows((oldRows) => [...oldRows, { id, isNew: true }])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    const getRowModesModel = () => {
        let rowModesModel = {}
        rows.forEach((row) => {
            rowModesModel[row.id] = { mode: GridRowModes.View }
        })
        return rowModesModel
    }

    const getEditToolbar = () => {
        return (
            <GridToolbarContainer>
                <Typography className={classes.tableTitle} variant="h6" component="h2">
                    {tableTitle}
                </Typography>
                {isEditable &&
                    <Button color="secondary" startIcon={<AddIcon />} onClick={() => handleEditToolBarClick()}>
                        Add record
                    </Button>
                }
            </GridToolbarContainer>
        )
    }
    const getRowsForTable = () => {
        return props.rows.map(row => ({
            ...row,
            id: randomId(),
        }))
    }

    const getColumnsForTable = () => {
        let columns = [...props.columns];
        if (props.isEditable) {
            columns.push(getActionsColumn());
        } else {
            // for all columns, make them not editable
            columns = columns.map(column => ({
                ...column,
                editable: false,
            }))
        }
        return columns
    }

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    }

    const getActionsColumn = () => {
        return (
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ]
                    }

                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ]
                }
            }
        )
    }

    const { classes } = props;
    return (
        <>
            {isEditable &&
                <Box
                    sx={{
                        height: rows.length === 0 ? 300 : 'auto',
                        maxHeight: 'auto',
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        disableRowSelectionOnClick
                        disableColumnSelector
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: getEditToolbar,
                        }}
                    />
                </Box>
            }

            {/* May be add some height to the rows ? */}
            {!isEditable &&
                <Box
                    sx={{
                        height: rows.length === 0 ? 300 : 'auto',
                        maxHeight: 'auto',
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        slots={{
                            toolbar: getEditToolbar,
                        }}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                    />
                </Box>
            }

            {isEditable &&
                <div className={classes.updateButton}>
                    <Button
                        variant="contained"
                        onClick={() => handleUpdateButton()}
                    >
                        Update
                    </Button>
                </div>
            }
        </>
    )
}

ABTable.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withResponseHandler(withStyles(ABTable, useStyles))