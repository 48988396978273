
// All validations return an array with a boolean and a string. The boolean is true if the validation passes, and false if it fails. The string is the error message if the validation fails, and an empty string if the validation passes.

import { getCountryCodes } from "../../constants/constants.ts";

export function validateVersion(version, latestVersion) {
    if (version === "" || version === undefined || version === null || version === "undefined") {
        return [false, "Version cannot be empty"]
    }

    if (version <= 0) {
        return [false, "Version should be > 0"]
    }

    if (version > latestVersion) {
        return [false, "Version should be less than or equal to the latest version"]
    }

    return [true, ""]
}

export function validateCohort(cohort) {
    if (cohort === "" || cohort === undefined || cohort === null || cohort === "undefined") {
        return [false, "Cohort cannot be empty"]
    }

    if (cohort < 0) {
        return [false, "Cohort should be a positive number"]
    }

    return [true, ""]
}

export function validateExperimentId(experimentId) {
    if (experimentId === "" || experimentId === undefined || experimentId === null || experimentId === "undefined") {
        return [false, "Experiment Id cannot be empty"]
    }

    if (experimentId.length < 1) {
        return [false, "Experiment Id should be atleast 8 characters long"]
    }

    if (experimentId.length > 15) {
        return [false, "Experiment ID should be atmost 30 characters long"]
    }

    // experiment ID should consists of only alphanumeric characters, and hyphen
    if (!/^[a-zA-Z0-9-]+$/.test(experimentId)) {
        return [false, "Experiment ID should consists of only alphanumeric characters, and hyphen"]
    }

    return [true, ""]
}

export function validateUserId(userId) {

    if (userId === "" || userId === undefined || userId === null || userId === "undefined") {
        return [false, "User Id cannot be empty"]
    }

    if (userId.length < 3) {
        return [false, "User ID should be atleast 3 characters long"]
    }

    if (userId.length > 40) {
        return [false, "User ID should be atmost 40 characters long"]
    }

    // user ID should consist of only alphanumeric characters
    if (!/^[a-zA-Z0-9]+$/.test(userId)) {
        return [false, "User ID should consists of only alphanumeric characters"]
    }

    return [true, ""]
}

export function validateExperimentName(experimentName) {
    if (experimentName === "" || experimentName === undefined || experimentName === null || experimentName === "undefined") {
        return [false, "Experiment Name cannot be empty"]
    }

    if (experimentName.length < 8) {
        return [false, "Experiment Name should be atleast 8 characters long"]
    }

    if (experimentName.length > 50) {
        return [false, "Experiment Name should be atmost 50 characters long"]
    }

    return [true, ""]
}

export function validateExperimentDescription(experimentDescription) {
    if (experimentDescription === "" || experimentDescription === undefined || experimentDescription === null || experimentDescription === "undefined") {
        return [false, "Experiment Description cannot be empty"]
    }

    if (experimentDescription.length < 8) {
        return [false, "Experiment Description should be atleast 10 characters long"]
    }

    if (experimentDescription.length > 200) {
        return [false, "Experiment Description should be atmost 200 characters long"]
    }

    return [true, ""]
}

export function validateCohortDescription(cohortDescription) {
    if (cohortDescription === "" || cohortDescription === undefined || cohortDescription === null || cohortDescription === "undefined") {
        return [false, "Cohort Description cannot be empty"]
    }

    if (cohortDescription.length < 5) {
        return [false, "Cohort Description should be atleast 5 characters long"]
    }

    if (cohortDescription.length > 200) {
        return [false, "Cohort Description should be atmost 200 characters long"]
    }

    return [true, ""]
}

export function validateDefaultVariant(defaultVariant) {
    if (defaultVariant === "" || defaultVariant === undefined || defaultVariant === null || defaultVariant === "undefined") {
        return [false, "Default Variant cannot be empty"]
    }

    return [true, ""]
}

export function validateVariantId(variantId) {
    if (variantId === "" || variantId === undefined || variantId === null || variantId === "undefined") {
        return [false, "Variant Id cannot be empty"]
    }

    if (variantId.length < 3) {
        return [false, "Variant ID should be atleast 3 characters long"]
    }

    return [true, ""]
}

export function validateCountryData(countryData) {
    if (countryData === "" || countryData === undefined || countryData === null || countryData === "undefined") {
        return [false, "Country Data cannot be empty"]
    }

    if (typeof countryData !== "object") {
        return [false, "Country Data should be an object"]
    }

    const countryCodes = getCountryCodes();

    for (const [country, variantId] of Object.entries(countryData)) {
        if (!countryCodes.includes(country)) {
            return [false, `Invalid country code: ${country}`];
        }

        const [isValidVariantId, variantIdErrorMessage] = validateVariantId(variantId);
        if (!isValidVariantId) {
            return [false, variantIdErrorMessage];
        }
    }

    return [true, ""]
}

export function validateStartTime(time) {
    if (time === "" || time === undefined || time === null || time === "undefined") {
        return [false, "Time cannot be empty"]
    }

    return [true, ""]
}
