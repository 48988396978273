import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Tab, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import ABTable from "../../components/ABTable";
import { VariantInfoTableHeaders } from "../../constants/constants";
import { randomId } from "@mui/x-data-grid-generator";
import withResponseHandler from "../../components/ResponseHandler";

const useStyles = (theme) => ({
    addVariantButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    submitVariantButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    cohortDataTable: {
        padding: theme.spacing(1),
    },
    variantInfoBox: {
        padding: theme.spacing(2),
    },
    variantDetailsTab: {
        padding: theme.spacing(1),
    },
    textField: {
        padding: theme.spacing(1),
    }
})

const VariantInfo = (props) => {

    // variables
    const AddVariantTab = "Add Variant Tab"

    // hooks
    const [selectedTab, setSelectedTab] = useState(AddVariantTab)
    const [tabs, setTabs] = useState([])
    const [tabIndex, setTabIndex] = useState(1)
    const [tabData, setTabData] = useState({})
    const [variantIdToIndexMap, setVariantIdToIndexMap] = useState({})
    const [tabValueToVariantIdMap, setTabValueToVariantIdMap] = useState({})

    useEffect(() => {
        if (props.variantData) {

            const variantIdToIndexMapCopy = {}
            Object.keys(props.variantData).forEach((variantId, index) => {
                variantIdToIndexMapCopy[variantId] = index + 1
            })
            setVariantIdToIndexMap(variantIdToIndexMapCopy)

            const tabValueToVariantIdMapCopy = {}
            Object.keys(props.variantData).forEach((variantId, index) => {
                tabValueToVariantIdMapCopy[`Variant ${index + 1}`] = variantId
            })
            setTabValueToVariantIdMap(tabValueToVariantIdMapCopy)

            const tabArr = Object.entries(props.variantData).map(([variantId, cohortData], index) => {
                return {
                    value: `Variant ${index + 1}`,
                    child: () => getVariantDetailsTab(variantId, index + 1, cohortData["cohortInfo"])
                }
            })
            setTabs(tabArr)

            setTabIndex(tabArr.length + 1)
            if (tabArr.length > 0) {
                setSelectedTab(tabArr[0].value)
            }

            setTabData(props.variantData)
        }
    }, [props.variantData])

    useEffect(() => {
        const tabArr = Object.entries(tabData).map(([variantId, cohortData], index) => {
            let indexAssociatedWithVariantId = variantIdToIndexMap[variantId]
            return {
                value: `Variant ${indexAssociatedWithVariantId}`,
                child: () => getVariantDetailsTab(variantId, indexAssociatedWithVariantId, cohortData["cohortInfo"])
            }
        })
        setTabs(tabArr)

    }, [tabData])

    // event handlers

    let closedTab = -1
    const handleChange = (event, newValue) => {

        if (newValue === closedTab) {
            closedTab = -1
            return
        }

        // check if newValue is present in tabs
        const tabArr = tabs.filter(x => x.value === newValue)
        if (tabArr.length === 0) {
            setSelectedTab(AddVariantTab)
        } else {
            setSelectedTab(newValue)
        }
    }

    const handleTabOptions = (value) => {
        setSelectedTab(value)
        setTabIndex(tabIndex + 1)
    }

    const handleTabClose = (event, value) => {
        const tabArr = tabs.filter(x => x.value !== value)
        setTabs(tabArr)

        let variantId = tabValueToVariantIdMap[value]
        const tabDataArr = Object.entries(tabData).filter(([key, value]) => key !== variantId)
        setTabData(Object.fromEntries(tabDataArr))

        closedTab = value

        if (selectedTab === value) {
            setSelectedTab(AddVariantTab)
        }
    }

    const handleAddVariantButton = () => {
        setTabs(prevTabs => [
            ...prevTabs,
            {
                value: `Variant ${tabIndex}`,
                child: () => getVariantDetailsTab(randomId(), tabIndex, {})
            }
        ])
        handleTabOptions(`Variant ${tabIndex}`)
    }

    const handleSubmitVariantButton = () => {
        props.callbackWithUpdatedVariantData(tabData)
    }

    // utilities

    const callbackOfCohortData = (data, randomId, tabIndex) => {

        props.handleSuccess({
            message: "Cohort data is updated successfully! Please dont forget to click on \"Submit Variant\" button to save the changes.",
            data: data,
        })

        let variantIdToIndexMapCopy = { ...variantIdToIndexMap }
        variantIdToIndexMapCopy[randomId] = tabIndex
        setVariantIdToIndexMap(variantIdToIndexMapCopy)

        let tabValueToVariantIdMapCopy = { ...tabValueToVariantIdMap }
        tabValueToVariantIdMapCopy[`Variant ${tabIndex}`] = randomId
        setTabValueToVariantIdMap(tabValueToVariantIdMapCopy)

        let tabDataCopy = { ...tabData }
        if (!tabDataCopy[randomId]) {
            tabDataCopy[randomId] = {}
        }

        const dataMap = data.reduce((map, item, index) => {
            map[index] = item;
            return map
        }, {})

        tabDataCopy[randomId]["cohortInfo"] = dataMap
        setTabData(tabDataCopy)
    }

    const getStaticTextBox = (label, defaultValue) => {
        return (
            <div className={classes.textField}>
                <TextField
                    disabled
                    id="static-text-box"
                    label={label}
                    defaultValue={defaultValue}
                    value={defaultValue}
                    variant="outlined"
                    style={{ width: '80%' }}
                />
            </div>
        )
    }

    const getVariantDetailsTab = (randomId, tabIndex, cohortTableData) => {
        return (
            <div className={classes.variantDetailsTab}>
                {getStaticTextBox("Variant ID", randomId)}
                {props.isEditable && getEditableCohortTable(randomId, tabIndex, cohortTableData)}
                {!props.isEditable && getStaticCohortTable(cohortTableData)}
            </div>
        )
    }

    const getCohortTableRows = (cohortTableData) => {

        if (typeof cohortTableData !== 'object' || cohortTableData === null || cohortTableData.length === 0) {
            return []
        }

        if (Array.isArray(cohortTableData)) {
            alert("cohortTableData is an array. It should be map")
            return
        }

        let cohortData = []
        let len = Object.keys(cohortTableData).length

        for (let i = 0; i < len; i++) {
            let key = i.toString()
            cohortData.push({
                cohortNo: cohortTableData[key]?.cohortNo,
                cohortPercentage: cohortTableData[key]?.cohortPercentage,
                cohortDetails: cohortTableData[key]?.cohortDetails,
                cohortConfig: cohortTableData[key]?.cohortConfig,
            })
        }

        return cohortData
    }

    const getEditableCohortTable = (randomId, tabIndex, cohortTableData) => {
        return (
            <div className={classes.cohortDataTable}>
                <ABTable
                    rows={getCohortTableRows(cohortTableData)}
                    columns={VariantInfoTableHeaders}
                    isEditable={true}
                    tableTitle={"Cohort Info"}
                    callbackWithUpdatedData={(data) => callbackOfCohortData(data, randomId, tabIndex)}
                />
            </div>
        )
    }

    const getStaticCohortTable = (cohortTableData) => {
        return (
            <div className={classes.cohortDataTable}>
                <ABTable
                    rows={getCohortTableRows(cohortTableData)}
                    columns={VariantInfoTableHeaders}
                    isEditable={false}
                    tableTitle={"Cohort Info"}
                />
            </div>
        )
    }

    const getMainTabPanel = () => {
        return (
            <TabPanel value={AddVariantTab}>
                <div className={classes.addVariantButton}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAddVariantButton()}
                    >
                        Add Variant
                    </Button>
                </div>
                <div className={classes.submitVariantButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmitVariantButton()}
                    >
                        Submit All Variants
                    </Button>
                </div>
            </TabPanel>
        )
    }

    // render
    const { classes } = props;
    return (
        <div className={classes.variantInfoBox}>
            <TabContext value={selectedTab}>
                <TabList onChange={handleChange}>

                    {props.isEditable &&
                        <Tab
                            label={"Add / Submit"}
                            value={AddVariantTab}
                        />
                    }

                    {tabs.map(tab => (
                        <Tab
                            icon={
                                props.isEditable ? <CloseIcon onClick={(e) => handleTabClose(e, tab.value)} /> : null
                            }
                            iconPosition='end'
                            key={tab.value}
                            label={tab.value}
                            value={tab.value}
                        />
                    ))}
                </TabList>


                {props.isEditable && getMainTabPanel()}
                {tabs.map(panel => (
                    <TabPanel
                        key={panel.value}
                        value={panel.value}
                    >
                        {panel.child()}
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    )
}
VariantInfo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withResponseHandler(withStyles(VariantInfo, useStyles))
