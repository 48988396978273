import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import withResponseHandler from "../../../components/ResponseHandler";
import ReactDiffViewer from 'react-diff-viewer';
import React, { useState } from "react";
import { Button, Divider, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import withLoader from "../../../components/Loader";
import { validateExperimentId } from "../../validators/ABExperimentationValidators"

const useStyles = (theme) => ({
    textField: {
        padding: theme.spacing(2),
    },
    submitButton: {
        padding: theme.spacing(2),
    },
    modalTitle: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#8FC9F9',
    },
    diffViewerBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        width: '95%',
        backgroundColor: '#383838',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    }
})

const VersionDifference = (props) => {
    // hooks
    const [experimentId, setExperimentId] = useState("")
    const [experimentIdError, setExperimentIdError] = useState(false)
    const [experimentIdHelperText, setExperimentIdHelperText] = useState("")

    const [version1, setVersion1] = useState("")
    const [version2, setVersion2] = useState("")
    const [version1Data, setVersion1Data] = useState({})
    const [version2Data, setVersion2Data] = useState({})
    const [showVersionDiffModal, setShowVersionDiffModal] = useState(false)

    // event handlers

    const handleExperimentIdChange = (event) => {
        let validate = validateExperimentId(event.target.value)
        if (validate[0]) {
            setExperimentIdError(false)
            setExperimentIdHelperText("")
        } else {
            setExperimentIdError(true)
            setExperimentIdHelperText(validate[1])
        }
        setExperimentId(event.target.value)
    }

    const handleVersion1Change = (event) => {
        setVersion1(event.target.value)
    }

    const handleVersion2Change = (event) => {
        setVersion2(event.target.value)
    }

    const handleHideModal = () => {
        setShowVersionDiffModal(false)
    }

    // utilities

    const getExperimentIdBox = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    id="experiment-id"
                    label="Experiment ID"
                    value={experimentId}
                    onChange={(event) => handleExperimentIdChange(event)}
                    fullWidth
                    variant="outlined"
                    error={experimentIdError}
                    helperText={experimentIdHelperText}
                />
            </div>
        )
    }

    const getVersion1Box = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    id="version1"
                    label="Version 1"
                    value={version1}
                    onChange={(event) => handleVersion1Change(event)}
                    fullWidth
                    variant="outlined"
                    type="number"
                    inputProps={{ min: "0", step: "1" }}
                />
            </div>
        )
    }

    const getVersion2Box = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    required
                    id="version2"
                    label="Version 2"
                    value={version2}
                    onChange={(event) => handleVersion2Change(event)}
                    fullWidth
                    variant="outlined"
                    type="number"
                    inputProps={{ min: "0", step: "1" }}
                />
            </div>
        )
    }

    const handleError = (error) => {
        props.handleError(error)
    }

    const handleSuccess = (data) => {
        props.handleSuccess(data)
    }

    const handleSubmit = async () => {

        if (experimentIdError) {
            alert("Please check the inputs")
            return
        }

        let version1Success = false
        let version2Success = false

        props.showLoader()
        const url1 = "/api/experiment/details/id/" + experimentId + "/version/" + version1
        try {
            const response1 = await axios.get(url1)
            props.hideLoader()
            version1Success = true
            setVersion1Data(JSON.stringify(response1.data.data, null, 1))
        } catch (error) {
            props.hideLoader()
            handleError(error)
        }

        props.showLoader()
        const url2 = "/api/experiment/details/id/" + experimentId + "/version/" + version2
        try {
            const response2 = await axios.get(url2)
            props.hideLoader()
            version2Success = true
            //handleSuccess(response2.data)
            setVersion2Data(JSON.stringify(response2.data.data, null, 1))
        } catch (error) {
            props.hideLoader()
            handleError(error)
        }

        if (version1Success && version2Success) {
            setShowVersionDiffModal(true)
        }
    }

    const getSubmitButton = () => {
        return (
            <div className={classes.submitButton}>
                <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            </div>
        )
    }

    const getDiffViewer = () => {
        return (
            <Modal
                open={showVersionDiffModal}
                onClose={() => handleHideModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={classes.diffViewerBox}>
                    <Typography id="modal-modal-title" className={classes.modalTitle} variant="h6" component="h2">
                        Version {version1} vs Version {version2}
                    </Typography>
                    <div id="modal-modal-description">
                        <ReactDiffViewer
                            oldValue={version1Data}
                            newValue={version2Data}
                            splitView={true}
                            showDiffOnly={false}
                            useDarkTheme={true}
                        />
                    </div>
                </div>
            </Modal>
        )
    }

    // render
    const { classes } = props;
    return (
        <>
            {getExperimentIdBox()}
            {getVersion1Box()}
            {getVersion2Box()}
            {getSubmitButton()}
            {getDiffViewer()}
        </>
    )
}
VersionDifference.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withLoader(withResponseHandler(withStyles(VersionDifference, useStyles)))
