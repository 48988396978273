import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import { Alert, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactJson from "react-json-view";

const useStyles = (theme) => ({
    successBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        boxShadow: 24,
        borderRadius: '10px',
        borderColor: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    modalTitle: {
        padding: theme.spacing(2),
    },
    modalDescription: {
        padding: theme.spacing(2),
    },
    blobPaper: {
        padding: theme.spacing(2),
    }
});

const SuccessHandling = (props) => {
    const handleModalClose = () => {
        props.onHandleClose()
    }

    const { classes } = props;
    return (
        <Modal
            open={props.shouldShow}
            onClose={() => handleModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes.successBox}>
                <Alert severity="success" onClose={() => { handleModalClose() }} >
                    <div id="modal-modal-title" className={classes.modalTitle}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            {props.success?.message}
                        </Typography>
                    </div>
                    <div id="modal-modal-description" className={classes.modalDescription}>
                        <Divider>
                            Success object below
                        </Divider>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.blobPaper}>
                                <ReactJson
                                    collapsed={true}
                                    src={props.success}
                                    theme={"summerfruit"}
                                    iconStyle="square"
                                />
                            </Paper>
                        </Grid>
                    </div>
                </Alert>
            </div>
        </Modal>
    )
}

SuccessHandling.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(SuccessHandling, useStyles);