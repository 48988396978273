import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  Paper,
  Select,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import ConfirmDialog from "../components/ConfirmDialog";
import DiceUploader from "../components/DiceUploader";
import Wrapper from "../components/Wrapper";
import { apiClient } from "../utils/apiClient";

const theme = createTheme({
  typography: {
    toolTipHeader: {
      color: "orange",
    },
    toolTipMessage: {
      color: "white",
    },
  },
});

function getToolTipLine(left, right) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="toolTipHeader">{left}</Typography>
        <Typography variant="toolTipMessage">{right}</Typography>
        <br></br>
      </ThemeProvider>
    </div>
  );
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  checkBoxPaper: {
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 18,
  },
});

const DiceUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isReUpload, setIsReUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [DiceInfos, setDiceInfos] = useState([]);
  const [addDiceEnabled, setAddDiceEnabled] = useState(false);

  const [diceUploaderCount, setDiceUploaderCount] = useState(1);

  const requiresConfirmDialog = useRef(false);
  const hasConfirmed = useRef(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  const handleOpenConfirmDialog = (msg) => {
    setConfirmationMsg(msg);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = (confirmed) => {
    setOpenConfirmDialog(false);
    if (confirmed) {
      hasConfirmed.current = true;
      publishDiceWithConfirmation();
    }
  };

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const addDiceInfos = (diceInfo) => {
    let newDiceInfo = true;
    if (DiceInfos.length > 0) {
      DiceInfos.map((v, i) => {
        if (diceInfo.DiceID == v.DiceID) {
          newDiceInfo = false;
        }
      });
    }

    if (newDiceInfo) {
      let diceInfos = DiceInfos;
      diceInfos.push(diceInfo);
      setDiceInfos(diceInfos);
    }
  };

  const publishDice = () => {
    if (requiresConfirmDialog.current && !hasConfirmed.current) {
      handleOpenConfirmDialog(confirmationMsg);
      return;
    }

    publishDiceWithConfirmation();
  };

  const publishDiceWithConfirmation = () => {
    if (!addDiceEnabled) {
      const key = enqueueSnackbar("Enter all details", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    let url = "/api/uploadDice/dev/" + currentServer;

    apiClient
      .post(url, DiceInfos, {
        params: {
          isReUpload: isReUpload,
          hasConfirmed: hasConfirmed.current,
        },
      })
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Dice Publish Failed! " + data.err, {
            variant: "error",
          });
        } else if (data.confirmation) {
          let confirmationMsg = data.confirmation;
          requiresConfirmDialog.current = true;
          handleOpenConfirmDialog(confirmationMsg);
        } else {
          const key = enqueueSnackbar("Dice Publish Successful", {
            variant: "success",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Dice Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const addDices = () => {
    setDiceUploaderCount(diceUploaderCount + 1);
  };

  const getDiceUploaders = () => {
    const diceUploader = [];

    for (let i = 0; i < diceUploaderCount; i++) {
      diceUploader.push(
        <DiceUploader
          id={diceUploaderCount}
          key={i}
          props={props}
          addDiceInfo={addDiceInfos}
          setEnableAddDice={setAddDiceEnabled}
          currEventDiceID={props.eventDiceID}
          currCosmeticDiceID={props.cosmeticDiceID}
          currRewardBoxDiceID={props.rewardBoxDiceID}
          serverEnv={currentServer}
        />
      );
    }

    return diceUploader;
  };

  const handleCheckerChange = () => {
    let confirm = true;
    if (!isReUpload) {
      confirm = window.confirm("Are you sure you want to reupload this dice!");
    }
    if (confirm) {
      setIsReUpload(!isReUpload);
    }
  };

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Wrapper heading="Dice Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={addDices}
              disabled={!addDiceEnabled || loading}
            >
              Add Dice
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Paper variant="outlined" className={classes.checkBoxPaper}>
              <Checkbox checked={isReUpload} onClick={handleCheckerChange} />
              <Typography variant="caption">Reupload Dice</Typography>
            </Paper>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Dice Upload File Info</Typography>
                  {getToolTipLine("Filename:", "	dice(number)_(ID Number).png")}
                  {getToolTipLine("", "Where number varies from 0-6")}
                  {getToolTipLine("Number:", "	7")}
                  {getToolTipLine(
                    "Filename:",
                    "	diceroll(number)_(ID Number).png"
                  )}
                  {getToolTipLine("", "Where number varies from 1-6")}
                  {getToolTipLine("Number:", "	6")}
                  {getToolTipLine("Filename:", "	diceface_(ID).png")}
                  {getToolTipLine("Number:", "	1")}
                  {getToolTipLine(
                    "",
                    "All Dice files must be of dimension 200X200"
                  )}
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ fontSize: 40 }}></InfoIcon>
            </Tooltip>
            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishDice}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <ConfirmDialog
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              dialogContentText={confirmationMsg}
            />
          </div>
        </Container>
        {getDiceUploaders()}
      </Wrapper>
    </div>
  );
};

DiceUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(DiceUpload, useStyles);
