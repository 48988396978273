import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import withResponseHandler from "../../components/ResponseHandler";
import {
    AppBar,
    Button,
    Divider,
    Grid,
    Modal, Paper,
    TextField,
    Toolbar,
    Typography,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import ABTable from "../../components/ABTable";
import { CountryDataTableHeaders } from "../../constants/constants";
import axios from "axios";
import withLoader from "../../components/Loader";
import VariantInfo from "./VariantInfo";
import {
    validateCohortDescription, validateCountryData, validateDefaultVariant, validateStartTime,
    validateVariantId
} from "../validators/ABExperimentationValidators";
import { getUserEmail } from "../../utils/tokenUtils";
import BiasTable from "../../components/BiasTable";

const useStyles = (theme) => ({
    addVersionBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        width: '85%',
        backgroundColor: '#383838',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    modalTitle: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#8FC9F9',
    },
    countryDataTable: {
        padding: theme.spacing(2),
    },
    submitButton: {
        padding: theme.spacing(2),
    },
    textField: {
        padding: theme.spacing(2),
    },
    defaultVariantTextBox: {
        padding: theme.spacing(2),
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
})

const AddVersion = (props) => {

    // hooks
    const [variantInfo, setVariantInfo] = useState({})
    const [countryData, setCountryData] = useState({})
    const [defaultVariant, setDefaultVariant] = useState("")
    const [startTime, setStartTime] = useState("")
    const [versionState, setVersionState] = useState(0)
    const [biasData, setBiasData] = useState([]);
    const [salt, setSalt] = useState("");

    useEffect(() => {
        setVariantInfo({})
        setCountryData({})
        setDefaultVariant("")
        setBiasData([])
        setStartTime("")
        if (props.experimentMetadata.latestVersion != 0) {
            setVersionState(2);
        } else {
            setVersionState(0);
        }
    }, [props.experimentMetadata.expId]);

    const handleModalClose = () => {
        props.addVersionModalClose()
    }

    const handleDefaultVariantChange = (event) => {
        setDefaultVariant(event.target.value)
    }

    // event handlers
    const handleSuccess = (data) => {
        props.handleSuccess(data)
    }

    const handleError = (error) => {
        props.handleError(error)
    }

    const getCreatedByEmail = () => {
        let createdByEmail = getUserEmail()
        return createdByEmail
    }

    const checkBiasforAllExperiments = async (data) => {
        if (data == null || data.length == 0) {
            props.hideLoader()
            setVersionState(2)
            return;
        }

        const countryDataClone = structuredClone(countryData);
        countryDataClone["ALL"] = defaultVariant
        let activeExpControlGroups = getCountryControlGroups(variantInfo, countryDataClone)
        let biasList = []
        for (let experiment of data) {
            if (experiment.expId == props.experimentMetadata.expId) {
                continue;
            }

            if (experiment.version == 0) {
                continue;
            }

            let biasMap = {}
            biasMap["expId"] = experiment.expId
            biasMap["expName"] = experiment.expName
            biasMap["bias"] = []
            let countryControlGroups = getCountryControlGroups(experiment.variantInfo, experiment.countryData)
            for (let activeExpcountry in activeExpControlGroups) {
                for (let country in countryControlGroups) {
                    try {
                        let response = await getDeviation(salt, activeExpControlGroups[activeExpcountry], experiment.expSalt, countryControlGroups[country]);
                        biasMap["bias"].push({ activeExpCountry: activeExpcountry, expCountry: country, deviation: response.deviation })
                    } catch (error) {
                        props.hideLoader()
                        props.handleError(error)
                        return
                    }
                }
            }
            biasList.push(biasMap);
        }

        setBiasData(biasList)
        setVersionState(2)
        props.hideLoader()
    }

    const getCountryControlGroups = (variantInfo, countryData) => {
        let countryControlGroups = {};
        for (const [country, variantId] of Object.entries(countryData)) {
            const variantIdInfo = variantInfo[variantId];
            const cohortInfo = variantIdInfo.cohortInfo;
            const percentages = Object.values(cohortInfo).map(cohort => cohort.cohortPercentage / 100);
            countryControlGroups[country] = percentages;
        }

        return countryControlGroups
    }

    const getDeviation = (exp1Salt, exp1ControlGroup, exp2Salt, exp2ControlGroup) => {
        const data = {
            exp1Salt: exp1Salt,
            exp1ControlGroup: exp1ControlGroup,
            exp2Salt: exp2Salt,
            exp2ControlGroup: exp2ControlGroup,
        }

        return axios.post("/api/experiment/deviation", data).then((response) => {
            return response.data;
        })
    };

    const generateSalt = async (data) => {
        if (data == null || data.length == 0) {
            props.hideLoader()
            setVersionState(1)
            return;
        }

        let expList = []
        for (let experiment of data) {
            if (experiment.expId == props.experimentMetadata.expId) {
                continue;
            }

            let countryControlGroups = getCountryControlGroups(experiment.variantInfo, experiment.countryData)
            for (let country in countryControlGroups) {
                expList.push({ "expSalt": experiment.expSalt, "controlGroup": countryControlGroups[country] })
            }
        }

        const countryDataClone = structuredClone(countryData);
        countryDataClone["ALL"] = defaultVariant
        let activeExpControlGroups = getCountryControlGroups(variantInfo, countryDataClone)
        let controlGroups = []
        for (let country in activeExpControlGroups) {
            controlGroups.push({ "controlGroup": activeExpControlGroups[country] })
        }

        let requestData = {
            controlGroups: controlGroups,
            expList: expList,
        }

        try {
            let response = await axios.post("/api/experiment/salt/generate", requestData).then((response) => {
                return response.data;
            })

            let salt = response.salt
            requestData = {
                expId: props.experimentMetadata.expId,
                expSalt: salt,
            }

            axios.put("/api/experiment/salt/update", requestData)
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    props.hideLoader()
                    setSalt(salt)
                    setVersionState(1)
                    props.handleSuccess(data)
                })
                .catch((error) => {
                    props.hideLoader()
                    props.handleError(error)
                })
        } catch (error) {
            props.hideLoader()
            props.handleError(error)
            return
        }
    }

    const handleGenerateSalt = () => {
        let data = {
            expId: props.experimentMetadata.expId,
            version: props.experimentMetadata.latestVersion + 1,
            createdBy: getCreatedByEmail(),
            variantInfo: variantInfo,
            countryData: countryData,
            defaultVariant: defaultVariant,
            startTime: startTime + ":00.000Z",
        }

        if (!validateAllData(data)) {
            return
        }

        const url = "/api/experiment/list/experiment/groups"
        props.showLoader()
        axios.get(url)
            .then((response) => {
                return response.data
            })
            .then((data) => {
                generateSalt(data.data)
            })
            .catch((error) => {
                props.hideLoader()
                props.handleError(error)
            })
    }

    const handleCheckBias = () => {
        const data = {
            expId: props.experimentMetadata.expId,
            version: props.experimentMetadata.latestVersion + 1,
            createdBy: getCreatedByEmail(),
            variantInfo: variantInfo,
            countryData: countryData,
            defaultVariant: defaultVariant,
            startTime: startTime + ":00.000Z",
        }

        if (!validateAllData(data)) {
            return
        }

        const url = "/api/experiment/list/experiment/groups"
        props.showLoader()
        axios.get(url)
            .then((response) => {
                return response.data
            })
            .then((data) => {
                checkBiasforAllExperiments(data.data)
            })
            .catch((error) => {
                props.hideLoader()
                props.handleError(error)
            })
    }

    const handleSubmitButton = () => {
        const data = {
            expId: props.experimentMetadata.expId,
            version: props.experimentMetadata.latestVersion + 1,
            createdBy: getCreatedByEmail(),
            variantInfo: variantInfo,
            countryData: countryData,
            defaultVariant: defaultVariant,
            startTime: startTime + ":00.000Z",
        }

        if (!validateAllData(data)) {
            return
        }

        props.showLoader()
        axios.post("/api/experiment/details/add/version", data)
            .then((response) => {
                props.hideLoader()
                return response.data;
            })
            .then((data) => {
                props.hideLoader()
                handleSuccess(data)
                props.refresh()
                handleModalClose()
            })
            .catch((error) => {
                props.hideLoader()
                handleError(error)
            })
    }

    const getSubmitButtonText = () => {
        switch (versionState) {
            case 0:
                return "Generate Salt"
            case 1:
                return "Check Bias"
            case 2:
                return "Submit Version"
        }
    }

    const getSubmitButton = () => {
        return (
            <div className={classes.submitButton}>
                <Button
                    variant="contained"
                    size="large"
                    style={{ width: 300, marginRight: 60 }}
                    color="success"
                    onClick={() => {
                        switch (versionState) {
                            case 0:
                                handleGenerateSalt()
                                break
                            case 1:
                                handleCheckBias()
                                break
                            case 2:
                                handleSubmitButton()
                                break
                        }
                    }}
                >
                    {getSubmitButtonText()}
                </Button>
            </div>
        )
    }

    const handleStartTimeChange = (event) => {
        setStartTime(event.target.value)
    }

    const getStaticTextBox = (label, defaultValue) => {
        return (
            <div className={classes.textField}>
                <TextField
                    disabled
                    fullWidth
                    id="static-text-box"
                    label={label}
                    defaultValue={defaultValue}
                    value={defaultValue}
                    variant="outlined"
                    style={{ width: '50%' }}
                />
            </div>
        )
    }

    const callbackOfVariantData = (data) => {
        let variantInfoCopy = {}

        Object.entries(data).forEach(([variantId, value]) => {
            if (!variantInfoCopy[variantId]) {
                variantInfoCopy[variantId] = {
                    cohortInfo: {}
                }
            }

            if (Array.isArray(value["cohortInfo"]) || value["cohortInfo"] === null) {
                alert("Cohort Info is not valid")
                return
            }

            let len = Object.keys(value["cohortInfo"]).length
            for (let i = 0; i < len; i++) {
                let key = i.toString()
                variantInfoCopy[variantId]["cohortInfo"][key] = {
                    cohortNo: value["cohortInfo"][key].cohortNo,
                    cohortPercentage: value["cohortInfo"][key].cohortPercentage,
                    cohortDetails: value["cohortInfo"][key].cohortDetails,
                    cohortConfig: value["cohortInfo"][key].cohortConfig,
                }
            }
        })

        setVariantInfo(variantInfoCopy)

        props.handleSuccess({
            message: "VariantInfo is updated successfully! Please check below -",
            data: variantInfoCopy,
        })
    }

    const getVariantInfoBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Variant Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <VariantInfo
                    isEditable={true}
                    variantData={variantInfo}
                    callbackWithUpdatedVariantData={(data) => callbackOfVariantData(data)}
                />
            </Paper>
        )
    }

    const callbackOfCountryData = (data) => {
        let countryVariantMap = {}
        data.forEach(item => {
            countryVariantMap[item.country] = item.variant
        })
        setCountryData(countryVariantMap)

        props.handleSuccess({
            message: "CountryData is updated successfully! Please check below -",
            data: countryVariantMap,
        })
    }

    const getRowsForCountryData = () => {
        let rows = []

        if (countryData === undefined || countryData === null) return rows

        Object.entries(countryData).forEach(([country, variantId]) => {
            rows.push({
                country: country,
                variant: variantId,
            })
        })

        return rows
    }

    const getCountryDataBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Country Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.countryDataTable}>
                    <ABTable
                        rows={getRowsForCountryData()}
                        columns={CountryDataTableHeaders}
                        isEditable={true}
                        tableTitle={"Country Data"}
                        callbackWithUpdatedData={(data) => callbackOfCountryData(data)}
                    />
                </div>
            </Paper>
        )
    }

    const getDefaultVariantBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Default Variant ID
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.defaultVariantTextBox}>
                    <TextField
                        fullWidth
                        id="default-variant-box"
                        label="Default Variant ID"
                        variant="outlined"
                        defaultValue={defaultVariant}
                        value={defaultVariant}
                        onChange={(event) => handleDefaultVariantChange(event)}
                    />
                </div>
            </Paper>
        )
    }

    const getBiasDetailsBlock = () => {
        if (biasData.length == 0) {
            return
        }

        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Bias Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.countryDataTable}>
                    <BiasTable biasData={biasData}></BiasTable>
                </div>
            </Paper>
        )
    }

    const getStartTime = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    label="Start time in UTC"
                    type="datetime-local"
                    value={startTime}
                    onChange={(event) => handleStartTimeChange(event)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        )
    }

    const validateAllData = (data) => {

        // validate default variant
        let defaultVariantValidation = validateDefaultVariant(data.defaultVariant)
        if (!defaultVariantValidation[0]) {
            alert(defaultVariantValidation[1])
            return false
        }

        // Validate variant Info
        for (let [variantId, variantDetails] of Object.entries(data.variantInfo)) {
            if (validateVariantId(variantId)[0] === false) {
                alert(validateVariantId(variantId)[1])
                return false
            }

            let sumOfPercentages = 0
            for (let [rowNo, cohortInfo] of Object.entries(variantDetails["cohortInfo"])) {
                if (cohortInfo["cohortPercentage"] === 0) {
                    alert("Cohort Percentage cannot be 0")
                    return false
                } else {
                    sumOfPercentages += parseInt(cohortInfo["cohortPercentage"])
                }

                let cohortDescriptionValidation = validateCohortDescription(cohortInfo["cohortDetails"])
                if (cohortDescriptionValidation[0] === false) {
                    alert(cohortDescriptionValidation[1])
                    return false
                }
            }

            if (sumOfPercentages !== 100) {
                alert("Sum of cohort percentages should be 100")
                return false
            }
        }

        // validate country data
        let countryDataValidation = validateCountryData(data.countryData)
        if (countryDataValidation[0] === false) {
            alert(countryDataValidation[1])
            return false
        }

        // validate startTime
        let startTimeValidation = validateStartTime(data.startTime)
        if (startTimeValidation[0] === false) {
            alert(startTimeValidation[1])
            return false
        }

        return true
    }

    // render
    const { classes } = props;
    return (
        <>
            <Modal
                open={props.showAddVersionModal}
                onClose={() => handleModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={classes.addVersionBox}>
                    <div className={classes.header}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography id="modal-modal-title" className={classes.modalTitle} variant="h6" component="h2">
                                Add New Version
                            </Typography>
                        </div>
                        {getSubmitButton()}
                        <IconButton
                            edge="start"
                            variant="contained"
                            color="inherit"
                            onClick={() => handleModalClose()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    <div id="modal-modal-description">
                        <Grid container spacing={2}>
                            <Grid item xs={3} md={3} lg={3}>
                                {getStaticTextBox("Experiment ID", props.experimentMetadata.expId)}
                                {getStaticTextBox("Experiment Name", props.experimentMetadata.expName)}
                                {getStaticTextBox("Version", props.experimentMetadata.latestVersion + 1)}
                                {getStartTime()}
                            </Grid>

                            <Grid item xs={9} md={9} lg={9} style={{ padding: 30 }}>
                                {getBiasDetailsBlock()}
                                <br />
                                {getVariantInfoBox()}
                                <br />
                                {getDefaultVariantBox()}
                                <br />
                                {getCountryDataBox()}
                                <br />
                            </Grid>
                        </Grid>
                    </div>
                </div>

            </Modal >
        </>
    )
}

AddVersion.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withLoader(withResponseHandler(withStyles(AddVersion, useStyles)))
