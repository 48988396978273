import React from "react";
import { withStyles } from 'tss-react/mui';
import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Wrapper from "../../components/Wrapper";
import { Button, Grid, Container } from "@mui/material";
import ExperimentList from "./ExperimentList";
import AddExperiment from "./AddExperiment";
import ExperimentUtilities from "./ExperimentUtilities";
import withResponseHandler from "../../components/ResponseHandler";
import axios from "axios";
import withLoader from "../../components/Loader";

// use styles hook
const useStyles = (theme) => ({
    container: {
        paddingTop: theme.spacing(0),
        padding: theme.spacing(0),
    },
    header: {
        display: "flex",
        alignItems: "center",
        paddingBottom: theme.spacing(1),
    },
    viewExperimentListButton: {
        margin: theme.spacing(2),
    },
    addExperimentButton: {
        margin: theme.spacing(2),
    }
})

const ABExperimentation = (props) => {

    // hooks
    const [experimentMetadata, setExperimentMetadata] = useState([])
    const [showAddExperimentModal, setShowAddExperimentModal] = useState(false)

    useEffect(() => {
        getAllExperimentMetadata()
    }, []);

    // event handlers
    const handleAddExperimentModalClose = () => {
        setShowAddExperimentModal(!showAddExperimentModal)
    }

    const handleViewExperimentListButton = () => {
        getAllExperimentMetadata()
    }

    const handleAddExperimentClick = () => {
        setShowAddExperimentModal(!showAddExperimentModal)
    }

    // utilities
    const getExperimentListButton = () => {
        return (
            <Button
                className={classes.viewExperimentListButton}
                variant="contained"
                onClick={() => handleViewExperimentListButton()}
            >
                Refresh
            </Button>
        )
    }

    const addExperimentButton = () => {
        return (
            <Button
                className={classes.addExperimentButton}
                variant="contained"
                onClick={() => handleAddExperimentClick()}
            >
                Add Experiment
            </Button>
        )
    }

    const getExperimentListButtonsHeader = () => {
        return (
            <Container maxWidth="false" style={{ padding: 0 }}>
                <div className={classes.header}>
                    <div style={{ flexGrow: 1 }}></div>
                    {getExperimentListButton()}
                    {addExperimentButton()}
                </div>
            </Container>
        )
    }

    const getAllExperimentMetadata = () => {
        const state = "3" // all experiments
        const url = "/api/experiment/list/state/" + state

        props.showLoader()
        axios.get(url)
            .then((response) => {
                props.hideLoader()
                return response.data
            })
            .then((data) => {
                props.hideLoader()
                setExperimentMetadata(() => (data.data));
            })
            .catch((error) => {
                props.hideLoader()
                props.handleError(error)
            })
    }

    // return
    const { classes } = props
    return (
        <>
            <Wrapper heading="AB Experimentation" setIsLoggedIn={props.setIsLoggedIn}>
                <Container maxWidth="lg">
                    <Grid item xs={12}>
                        {getExperimentListButtonsHeader()}
                        <ExperimentList experimentList={experimentMetadata} refresh={() => getAllExperimentMetadata()} />
                    </Grid>

                    <br />
                    <br />
                    <br />
                    <br />
                    <Grid item xs={12}>
                        <ExperimentUtilities />
                    </Grid>
                </Container>
                <AddExperiment
                    shouldShow={showAddExperimentModal}
                    refresh={() => getAllExperimentMetadata()}
                    onHandleClose={() => handleAddExperimentModalClose()}
                />
            </Wrapper>
        </>
    )

}

ABExperimentation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withLoader(withResponseHandler(withStyles(ABExperimentation, useStyles)))