import React, { useEffect } from "react";
import { withStyles } from 'tss-react/mui';
import { useState } from "react";
import { PropTypes } from "prop-types";
import {
    AppBar, Button, FormControl, Grid, InputLabel, MenuItem,
    Modal, Paper, Select,
    TextField, Toolbar, Typography, IconButton,
    Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ABTable from "../../components/ABTable";
import {
    CountryDataTableHeaders,
    ExperimentMetadataTableHeaders,
} from "../../constants/constants";
import AddVersion from "./AddVersion";
import axios from "axios";
import withResponseHandler from "../../components/ResponseHandler";
import VariantInfo from "./VariantInfo";
import { getUserEmail } from "../../utils/tokenUtils";
import withLoader from "../../components/Loader";
import ReactJson from "react-json-view";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import { Environment, EnvironmentType } from "../../globals/conf";
import BiasTable from "../../components/BiasTable";

// use styles hook
const useStyles = (theme) => ({
    versionDropdown: {
        margin: theme.spacing(2),
        minWidth: 150,
    },
    viewExperimentDetailsModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        width: '85%',
        backgroundColor: '#383838',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    variantInfoTable: {

    },
    countryDataTable: {
        padding: theme.spacing(2),
    },
    modalTitle: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#8FC9F9',
    },
    addVersionButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    metadataBasicDetailsTable: {
        padding: theme.spacing(2),
    },
    textField: {
        padding: theme.spacing(2),
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    accordian: {
        padding: theme.spacing(2),
    }
})

const ViewExperimentDetails = (props) => {

    // hooks
    const [versionNo, setVersionNo] = useState(0)
    const [showAddVersionModal, setShowAddVersionModal] = useState(false)
    const [experimentData, setExperimentData] = useState({})
    const [biasData, setBiasData] = useState([]);

    /*
      Props.experimentData always consists of latest version data
      We alter the data in experimentData and show the version details selected by the user
     */
    useEffect(() => {
        setVersionNo(props.experimentMetadata.latestVersion)
        setExperimentData(props.experimentData)
        setBiasData([])
    }, [props.experimentMetadata.latestVersion, props.experimentData])

    // event handlers
    const handleModalClose = () => {
        props.viewExperimentDetailsModalClose()
    }

    const handleVersionChange = (event) => {
        if (event.target.value === versionNo) {
            return
        }

        const url = "/api/experiment/details/id/" + props.experimentMetadata.expId + "/version/" + event.target.value
        props.showLoader()
        axios.get(url)
            .then((response) => {
                props.hideLoader()
                return response.data
            })
            .then((data) => {
                props.hideLoader()
                setExperimentData(data.data)
                setVersionNo(event.target.value)
            })
            .catch((error) => {
                props.hideLoader()
                props.handleError(error)
            })
    }

    const handleAddVersionButton = () => {
        props.viewExperimentDetailsModalClose()
        setShowAddVersionModal(true)
    }

    const handleAddVersionModalClose = () => {
        setShowAddVersionModal(false)
    }

    // utilities

    const getRowsForMetadata = () => {
        return [
            {
                index: "ID",
                details: props.experimentMetadata.expId,
            },
            {
                index: "Name",
                details: props.experimentMetadata.expName,
            },
            {
                index: "Description",
                details: props.experimentMetadata.expDescription,
            },
            {
                index: "Hypothesis",
                details: props.experimentMetadata.expHypothesis,
            },
            {
                index: "Primary Metrics (OEC)",
                details: props.experimentMetadata.primaryMetrics,
            },
            {
                index: "Secondary Metrics",
                details: props.experimentMetadata.secondaryMetrics,
            },
            {
                index: "Gaurdrail Metrics",
                details: props.experimentMetadata.gaurdrailMetrics,
            },
            {
                index: "State",
                details: props.experimentMetadata.state,
            },
            {
                index: "Latest Version",
                details: props.experimentMetadata.latestVersion,
            },
            {
                index: "Created At",
                details: props.experimentMetadata.createdAt,
            },
            {
                index: "Updated At",
                details: props.experimentMetadata.updatedAt,
            },
            {
                index: "Created By",
                details: props.experimentMetadata.createdBy,
            },
            {
                index: "Revision",
                details: props.experimentMetadata.revision,
            }
        ]
    }

    const getExperimentBasicDetailsBlock = () => {
        return (
            <div className={classes.metadataBasicDetailsTable}>
                <ABTable
                    rows={getRowsForMetadata()}
                    columns={ExperimentMetadataTableHeaders}
                    isEditable={false}
                    tableTitle={"Experiment Metadata"}
                />
            </div>
        )
    }

    const getVersionDetailsOutline = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Version Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div>
                    <FormControl className={classes.versionDropdown}>
                        <InputLabel id="simple-select-label">Version</InputLabel>
                        <Select
                            labelId="simple-select-label"
                            id="demo-simple-select"
                            value={versionNo}
                            label="Version"
                            onChange={(event) => handleVersionChange(event)}
                        >
                            {
                                [...Array(props.experimentMetadata.latestVersion)].map((_, i) => (
                                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </Paper>
        )
    }

    const getVariantDetailsBlock = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Variant Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <VariantInfo
                    isEditable={false}
                    variantData={experimentData?.variantInfo}
                />
            </Paper>
        )
    }

    const getRowsForCountryData = () => {
        let rows = []

        if (experimentData === null) return rows
        if (experimentData.countryData === undefined || experimentData.countryData === null) return rows

        Object.entries(experimentData.countryData).forEach(([country, variantId]) => {
            rows.push({
                country: country,
                variant: variantId,
            })
        })

        return rows
    }

    const getCountryDetailsBlock = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Country Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.countryDataTable}>
                    <ABTable
                        rows={getRowsForCountryData()}
                        columns={CountryDataTableHeaders}
                        isEditable={false}
                        tableTitle={"Country Data"}
                    />
                </div>
            </Paper>
        )
    }

    const getBiasDetailsBlock = () => {
        if (biasData.length == 0) {
            return
        }

        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Bias Details
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.countryDataTable}>
                    <BiasTable biasData={biasData}></BiasTable>
                </div>
            </Paper>
        )
    }

    const getDefaultVariantBlock = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Default Variant
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.textField}>
                    <TextField
                        disabled
                        id="static-text-box"
                        label={"Default Variant"}
                        defaultValue={experimentData?.defaultVariant}
                        value={experimentData?.defaultVariant}
                        variant="outlined"
                        style={{ width: '70%' }}
                    />
                </div>
            </Paper>
        )
    }

    const getStartTime = () => {
        return (
            <div className={classes.textField}>
                <TextField
                    disabled
                    id="static-text-box"
                    label={"Start Time in UTC"}
                    defaultValue={experimentData?.startTime}
                    value={experimentData?.startTime}
                    variant="outlined"
                    style={{ width: '50%' }}
                />
            </div>
        )
    }

    const addVersionButton = () => {
        return (
            <div className={classes.addVersionButton}>
                <Button
                    variant="contained"
                    size="large"
                    style={{ width: 300, marginRight: 60 }}
                    color="error"
                    onClick={() => handleAddVersionButton()}
                >
                    Add New Version
                </Button>
            </div>
        )
    }

    const handleCheckBias = () => {
        const url = "/api/experiment/list/experiment/groups"
        props.showLoader()
        axios.get(url)
            .then((response) => {
                return response.data
            })
            .then((data) => {
                checkBiasforAllExperiments(data.data)
            })
            .catch((error) => {
                props.hideLoader()
                props.handleError(error)
            })
    }

    const checkBiasforAllExperiments = async (data) => {
        const countryDataClone = structuredClone(props.experimentData.countryData);
        countryDataClone["ALL"] = props.experimentData.defaultVariant
        let activeExpControlGroups = getCountryControlGroups(props.experimentData.variantInfo, countryDataClone)
        let biasList = []
        for (let experiment of data) {
            if (experiment.expId == props.experimentMetadata.expId) {
                continue;
            }

            if (experiment.version == 0) {
                continue;
            }

            let biasMap = {}
            biasMap["expId"] = experiment.expId
            biasMap["expName"] = experiment.expName
            biasMap["bias"] = []
            let countryControlGroups = getCountryControlGroups(experiment.variantInfo, experiment.countryData)
            for (let activeExpcountry in activeExpControlGroups) {
                for (let country in countryControlGroups) {
                    try {
                        let response = await getDeviation(props.experimentMetadata.expSalt, activeExpControlGroups[activeExpcountry], experiment.expSalt, countryControlGroups[country]);
                        biasMap["bias"].push({ activeExpCountry: activeExpcountry, expCountry: country, deviation: response.deviation })
                    } catch (error) {
                        props.hideLoader()
                        props.handleError(error)
                        return
                    }
                }
            }
            biasList.push(biasMap);
        }

        setBiasData(biasList)
        props.hideLoader()
    }

    const getCountryControlGroups = (variantInfo, countryData) => {
        let countryControlGroups = {};
        for (const [country, variantId] of Object.entries(countryData)) {
            const variantIdInfo = variantInfo[variantId];
            const cohortInfo = variantIdInfo.cohortInfo;
            const percentages = Object.values(cohortInfo).map(cohort => cohort.cohortPercentage / 100);
            countryControlGroups[country] = percentages;
        }

        return countryControlGroups
    }


    const getDeviation = (exp1Salt, exp1ControlGroup, exp2Salt, exp2ControlGroup) => {
        const data = {
            exp1Salt: exp1Salt,
            exp1ControlGroup: exp1ControlGroup,
            exp2Salt: exp2Salt,
            exp2ControlGroup: exp2ControlGroup,
        }

        return axios.post("/api/experiment/deviation", data).then((response) => {
            return response.data;
        })
    };

    const getBiasCheckButton = () => {
        return (
            <div className={classes.submitButton}>
                <Button
                    variant="contained"
                    size="large"
                    style={{ width: 200, marginRight: 20 }}
                    color="success"
                    onClick={() => handleCheckBias()}
                >
                    Check Bias
                </Button>
            </div>
        )
    }

    const isUserAllowedToEdit = () => {
        let userEmail = getUserEmail()

        if (userEmail === null || userEmail === undefined || userEmail === "") {
            return true
        }

        if (Environment.env == EnvironmentType.Local) {
            return true
        }

        if (userEmail === props.experimentMetadata.createdBy) {
            return true
        }

        if (props.experimentMetadata === null || props.experimentMetadata === undefined) {
            return false
        }

        if (props.experimentMetadata.editAccess === null || props.experimentMetadata.editAccess === undefined) {
            return false
        }

        return props.experimentMetadata.editAccess.includes(userEmail)
    }

    const getJsonViewBox = () => {
        return (
            <div className={classes.accordian}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Json data for version: {versionNo}
                    </AccordionSummary>
                    {versionNo !== 0 &&
                        <AccordionDetails>
                            <div>
                                <Paper className={classes.blobPaper}>
                                    <ReactJson
                                        collapsed={false}
                                        src={experimentData}
                                        theme={"summerfruit"}
                                        iconStyle="square"
                                        enableClipboard={true}
                                    />
                                </Paper>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => { navigator.clipboard.writeText(JSON.stringify(experimentData)) }}
                                >
                                    Copy
                                </Button>
                            </div>
                        </AccordionDetails>
                    }
                </Accordion>
            </div>
        )
    }

    // return
    const { classes } = props;
    return (
        <>
            <Modal
                open={props.viewExperimentDetailsModal}
                onClose={() => handleModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={classes.viewExperimentDetailsModal}>
                    <div className={classes.header}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography id="modal-modal-title" className={classes.modalTitle} variant="h5" component="h2">
                                Experiment Details
                            </Typography>
                        </div>
                        {props.experimentMetadata.latestVersion !== 0 &&
                            <>
                                {getBiasCheckButton()}
                            </>
                        }
                        {isUserAllowedToEdit() && addVersionButton()}
                        <IconButton
                            edge="start"
                            variant="contained"
                            color="inherit"
                            onClick={() => handleModalClose()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <div id="modal-modal-description">
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={4} lg={4}>
                                {getExperimentBasicDetailsBlock()}
                                {props.experimentMetadata.latestVersion !== 0 &&
                                    <>
                                        {getStartTime()}
                                    </>
                                }
                            </Grid>
                            <Grid item xs={8} md={8} lg={8} style={{ padding: 25 }}>
                                {props.experimentMetadata.latestVersion !== 0 &&
                                    <>
                                        {getBiasDetailsBlock()}
                                        <br />
                                        {getVersionDetailsOutline()}
                                        <br />
                                        {getVariantDetailsBlock()}
                                        <br />
                                        {getDefaultVariantBlock()}
                                        <br />
                                        {getCountryDetailsBlock()}
                                    </>
                                }
                                {getJsonViewBox()}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

            <AddVersion
                refresh={() => props.refresh()}
                showAddVersionModal={showAddVersionModal}
                addVersionModalClose={() => handleAddVersionModalClose()}
                experimentMetadata={props.experimentMetadata}
                version={props.experimentMetadata.latestVersion + 1}
                experimentData={props.experimentData}
            />
        </>
    )

}

ViewExperimentDetails.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withLoader(withResponseHandler(withStyles(ViewExperimentDetails, useStyles)))