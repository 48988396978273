import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import AvatarUploader from "../components/AvatarUploader";
import InfoIcon from "@mui/icons-material/Info";

const theme = createTheme({
  typography: {
    toolTipHeader: {
      color: "orange",
    },
    toolTipMessage: {
      color: "white",
    },
  },
});

function getToolTipLine(left, right) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="toolTipHeader">{left}</Typography>
        <Typography variant="toolTipMessage">{right}</Typography>
        <br></br>
      </ThemeProvider>
    </div>
  );
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const AvatarUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [avatarUploaders, setAvatarUploaders] = useState([]);
  const [avatarInfos, setAvatarInfos] = useState([]);
  const [addAvatarEnabled, setAddAvatarEnabled] = useState([]);

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  };

  const setEnableAddAvatar = (isEnabled) => {
    setAddAvatarEnabled(isEnabled);
  };

  const publishAvatar = () => {
    if (!addAvatarEnabled) {
      const key = enqueueSnackbar("Enter all details", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    let url = "/api/uploadAvatar/dev/" + currentServer;
    axios
      .post(url, avatarInfos)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Avatar Publish Failed", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("Avatar Publish Successful", {
            variant: "success",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Avatar Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const addAvatarInfos = (avatarInfo) => {
    var newAvatarInfo = true;
    if (avatarInfos.length > 0) {
      avatarInfos.map((v, i) => {
        if (avatarInfo.AvatarID == v.AvatarID) {
          newAvatarInfo = false;
        }
      });
    }

    if (newAvatarInfo) {
      setAvatarInfos((current) => [...current, avatarInfo]);
    }
  };

  const addAvatars = () => {
    setAddAvatarEnabled(false);
    setAvatarUploaders((current) => [
      current,
      <AvatarUploader
        props={props}
        addAvatarInfo={addAvatarInfos}
        setEnableAddAvatar={setEnableAddAvatar}
        avatarID={props.avatarID}
      />,
    ]);
  };

  const getAvatarUploader = () => {
    var avatarUploader = [];

    if (avatarUploaders.length > 0) {
      avatarUploader = avatarUploaders;
    } else {
      let avatarObj = (
        <AvatarUploader
          props={props}
          addAvatarInfo={addAvatarInfos}
          setEnableAddAvatar={setEnableAddAvatar}
          avatarID={props.avatarID}
        />
      );
      avatarUploader.push(avatarObj);
      setAvatarUploaders((current) => [current, avatarObj]);
    }

    return avatarUploader;
  };

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Wrapper heading="Avatar Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={addAvatars}
              disabled={!addAvatarEnabled || loading}
            >
              Add Avatar
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Avatar Upload File Info
                  </Typography>
                  {getToolTipLine("FileName:", " sa_(ID number).png")}
                  {getToolTipLine("Dimensions:", "	400 X 400 exact")}
                  {getToolTipLine("Number:", "	1")}
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ fontSize: 40 }}></InfoIcon>
            </Tooltip>
            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishAvatar}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
        </Container>
        {getAvatarUploader()}
      </Wrapper>
    </div>
  );
};

AvatarUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(AvatarUpload, useStyles);
