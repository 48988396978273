import { PropTypes } from "prop-types";
import { withStyles } from "tss-react/mui";
import withResponseHandler from "../../components/ResponseHandler";
import ABTable from "../../components/ABTable";
import {
    ExperimentEditAccessHeaders,
    ExperimentMetadataTableHeaders,
    OverriddenUsersTableHeaders
} from "../../constants/constants";
import React, { useState } from "react";
import { AppBar, Button, Divider, Modal, Toolbar, Typography, IconButton, Grid, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditExperimentMetadata from "./EditExperimentMetadata";
import { getUserEmail } from "../../utils/tokenUtils";
import { Environment, EnvironmentType } from "../../globals/conf";

const useStyles = (theme) => ({
    overriddenUsersTable: {
        padding: theme.spacing(2),
    },
    editAccessTable: {
        padding: theme.spacing(2),
    },
    viewExperimentMetadataModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        width: '75%',
        backgroundColor: '#383838',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    metadataBasicDetailsTable: {
        padding: theme.spacing(2),
    },
    modalTitle: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#8FC9F9'
    },
    editMetadataButton: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
})

const ViewExperimentMetadata = (props) => {

    // hooks

    const [editExperimentMetadataModal, setEditExperimentMetadataModal] = useState(false)

    // event handlers

    const handleModalClose = () => {
        props.viewExperimentMetadataModalClose()
    }

    const handleEditMetadataButton = () => {
        props.viewExperimentMetadataModalClose()
        setEditExperimentMetadataModal(true)
    }

    const handleEditExperimentMetadataModalClose = () => {
        setEditExperimentMetadataModal(false)
    }

    // utilities

    const getRowsForMetadata = () => {
        return [
            {
                index: "Experiment ID",
                details: props.experimentMetadata.expId,
            },
            {
                index: "Experiment Name",
                details: props.experimentMetadata.expName,
            },
            {
                index: "Experiment Salt",
                details: props.experimentMetadata.expSalt,
            },
            {
                index: "Description",
                details: props.experimentMetadata.expDescription,
            },
            {
                index: "Hypothesis",
                details: props.experimentMetadata.expHypothesis,
            },
            {
                index: "Primary Metrics (OEC)",
                details: props.experimentMetadata.primaryMetrics,
            },
            {
                index: "Secondary Metrics",
                details: props.experimentMetadata.secondaryMetrics,
            },
            {
                index: "Gaurdrail Metrics",
                details: props.experimentMetadata.gaurdrailMetrics,
            },
            {
                index: "State",
                details: props.experimentMetadata.state,
            },
            {
                index: "Latest Version",
                details: props.experimentMetadata.latestVersion,
            },
            {
                index: "Created At",
                details: props.experimentMetadata.createdAt,
            },
            {
                index: "Updated At",
                details: props.experimentMetadata.updatedAt,
            },
            {
                index: "Created By",
                details: props.experimentMetadata.createdBy,
            },
            {
                index: "Revision",
                details: props.experimentMetadata.revision,
            }
        ]
    }

    const getRowsForOverriddenUsers = () => {

        if (!props || typeof props !== 'object' || !props.experimentMetadata || typeof props.experimentMetadata !== 'object') {
            return [];
        }

        if (props.experimentMetadata.overriddenUsers === null || props.experimentMetadata.overriddenUsers === undefined || typeof props.experimentMetadata.overriddenUsers !== 'object') {
            return [];
        }

        return Object.keys(props.experimentMetadata.overriddenUsers).map((key, index) => {
            return {
                userid: key,
                cohort: props.experimentMetadata.overriddenUsers[key].cohortNo,
                cohortConfig: props.experimentMetadata.overriddenUsers[key].cohortConfig,
            }
        })
    }

    const getRowsForEditAccessTable = () => {
        let rows = []

        if (!props || typeof props !== 'object' || !props.experimentMetadata || typeof props.experimentMetadata !== 'object') {
            return rows
        }

        if (props.experimentMetadata.editAccess === undefined || props.experimentMetadata.editAccess === null || props.experimentMetadata.editAccess.length === 0) {
            return rows
        }

        // filter createdBy from the editAccess list
        let createdBy = props.experimentMetadata?.createdBy
        let filteredEditAccess = props.experimentMetadata.editAccess.filter((email) => email !== createdBy)

        filteredEditAccess.forEach((row) => {
            rows.push({
                email: row
            })
        })
        return rows
    }


    const getMetadataBasicDetailsBox = () => {
        return (
            <div className={classes.metadataBasicDetailsTable}>
                <ABTable
                    rows={getRowsForMetadata()}
                    columns={ExperimentMetadataTableHeaders}
                    isEditable={false}
                    tableTitle={"Metadata"}
                />
            </div>
        )
    }

    const getOverriddenUsersBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Overridden Users
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.overriddenUsersTable}>
                    <ABTable
                        rows={getRowsForOverriddenUsers()}
                        columns={OverriddenUsersTableHeaders}
                        isEditable={false}
                        tableTitle={"Overridden Users"}
                    />
                </div>
            </Paper>
        )
    }

    const isUserAllowedToEdit = () => {
        let userEmail = getUserEmail()

        if (userEmail === null || userEmail === undefined || userEmail === "") {
            return true
        }

        if (Environment.env == EnvironmentType.Local) {
            return true
        }

        if (userEmail === props.experimentMetadata.createdBy) {
            return true
        }

        if (props.experimentMetadata === null || props.experimentMetadata === undefined) {
            return false
        }

        if (props.experimentMetadata.editAccess === null || props.experimentMetadata.editAccess === undefined) {
            return false
        }

        return props.experimentMetadata.editAccess.includes(userEmail)
    }

    const getEditMetadataButton = () => {
        return (
            <div className={classes.editMetadataButton}>
                <Button
                    size="large"
                    style={{ width: 300, marginRight: 60 }}
                    variant="contained"
                    color="error"
                    onClick={() => handleEditMetadataButton()}
                >
                    Edit Metadata
                </Button>
            </div>
        )
    }

    const getEditAccessBox = () => {
        return (
            <Paper>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Edit Access
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.editAccessTable}>
                    <ABTable
                        rows={getRowsForEditAccessTable()}
                        columns={ExperimentEditAccessHeaders}
                        isEditable={false}
                        tableTitle={"Edit Access"}
                    />
                </div>
            </Paper>
        )
    }

    // render
    const { classes } = props;
    return (
        <>
            <Modal
                open={props.viewExperimentMetadataModal}
                onClose={() => handleModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={classes.viewExperimentMetadataModal}>
                    <div className={classes.header}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography className={classes.modalTitle} variant="h5" component="h2">
                                Experiment Metadata
                            </Typography>
                        </div>
                        {isUserAllowedToEdit() &&
                            <>
                                {getEditMetadataButton()}
                            </>
                        }
                        <IconButton
                            edge="start"
                            variant="contained"
                            color="inherit"
                            onClick={() => handleModalClose()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <div id="modal-modal-description">
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} lg={6}>
                                {getMetadataBasicDetailsBox()}
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} style={{ padding: 15 }}>
                                <br></br>
                                {getOverriddenUsersBox()}
                                <br></br>
                                {getEditAccessBox()}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>

            <EditExperimentMetadata
                refresh={() => props.refresh()}
                showEditExperimentMetadataModal={editExperimentMetadataModal}
                editExperimentMetadataModalClose={() => handleEditExperimentMetadataModalClose(false)}
                experimentMetadata={props.experimentMetadata}
            />
        </>
    )
}
ViewExperimentMetadata.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withResponseHandler(withStyles(ViewExperimentMetadata, useStyles))
