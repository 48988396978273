import React, { useState } from 'react';
import ErrorHandling from "./ErrorHandling";
import SuccessHandling from "./SuccessHandling";

// This function is a HOC. We shall use this class to wrap around any component that we want to display error.
const withResponseHandler = (WrappedComponent) => {
    return (props) => {
        const [error, setError] = useState(null);
        const [showErrorModal, setShowErrorModal] = useState(false);
        const [success, setSuccess] = useState(null);
        const [showSuccessModal, setShowSuccessModal] = useState(false);

        const handleError = (error) => {
            setError(error)
            setShowErrorModal(true);
        }

        const handleSuccess = (success) => {
            setSuccess(success)
            setShowSuccessModal(true);
        }

        const handleClose = () => {
            setShowErrorModal(false);
            setError(null)
            setSuccess(null)
            setShowSuccessModal(false)
        }

        return (
            <>
                <WrappedComponent
                    {...props}
                    handleError={(error) => handleError(error)}
                    handleSuccess={(success) => handleSuccess(success)}
                />
                <ErrorHandling
                    shouldShow={showErrorModal}
                    onHandleClose={() => handleClose()}
                    error={error}
                />
                <SuccessHandling
                    shouldShow={showSuccessModal}
                    onHandleClose={() => handleClose()}
                    success={success}
                />
            </>
        );
    };
};

export default withResponseHandler;
