import React from "react";
import Wrapper from "../components/Wrapper";
import { withStyles } from "tss-react/mui";
import { Button, Typography, TextField, Grid } from "@mui/material";
import axios from "axios";
import { PropTypes } from "prop-types";
import { Component } from "react";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    margin: "5vh 10vw",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
});

class DynamicLink extends Component {
  state = {
    fbLink: "",
    instaLink: "",
    rewardNumber: 0,
    duration: 0,
    loading: false,
  };

  // TODO: Convert this to function and add Snackbar ASAP.

  getLink = () => {
    this.setState({ loading: true });
    let url =
      "/api/dynamicLink/" +
      this.state.duration +
      "/day/" +
      this.state.rewardNumber;
    axios
      .get(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        console.log(data.fb);
        if (!data.err) {
          this.setState({ fbLink: data["fb"], instaLink: data["insta"] });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Wrapper
          heading="Dynamic Link"
          setIsLoggedIn={this.props.setIsLoggedIn}
        >
          <div style={{ margin: "5vh 10vw" }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={3}>
                <TextField
                  id="textfield-number"
                  type="number"
                  label={"Duration"}
                  variant="outlined"
                  onChange={(event) => {
                    this.setState({ duration: event.target.value });
                  }}
                  value={this.state.duration}
                  name={"Duration"}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="textfield-number"
                  type="number"
                  label={"Reward No"}
                  variant="outlined"
                  onChange={(event) => {
                    this.setState({ rewardNumber: event.target.value });
                  }}
                  value={this.state.rewardNumber}
                  name={"Reward No"}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  onClick={() => {
                    this.getLink();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Generate
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <Typography variant="subheading">
                  <b>Fb Link -</b> {this.state.fbLink}
                </Typography>
                <br></br>
                <br></br>
                <Typography variant="subheading">
                  <b>Insta Link -</b> {this.state.instaLink}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center"></Grid>
          </div>
        </Wrapper>
      </div>
    );
  }
}

DynamicLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(DynamicLink, useStyles);
