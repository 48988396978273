import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Typography,
  Backdrop,
  IconButton,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import axios from "axios";
import Wrapper from "../../../components/Wrapper";
import ClubCosmeticAssetUploader from "../../../components/club/cosmetic/ClubCosmeticAssetUploader";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  checkBoxPaper: {
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 18,
  },
}));

const ClubCosmeticAssetUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [currentServer, setCurrentServer] = useState(0);
  const [assetCount, setAssetCount] = useState(0);
  const [assetUploaders, setAssetUploaders] = useState([]);
  const [assetInfos, setAssetInfos] = useState([]);
  const [validating, setValidating] = useState(false);
  const [activePanel, setActivePanel] = useState(0)
  const classes = useStyles();

  const handleServerChange = (event) => {
    setCurrentServer(event.target.value);
  }

  const getAssetUploader = () => {
    var assetUploader = [];

    if (assetInfos.length == 0) {
      addAssetUploaders()
    } else {
      for (let i = 0; i < assetInfos.length; i++) {
        assetUploader.push(getAssetAccordion(assetInfos[i]))
      }
    }

    setAssetUploaders(assetUploader)
  };

  useEffect(() => {
    getAssetUploader()
  }, [assetInfos, validating, activePanel])

  const setAssetInfo = (assetInfo) => {
    let ais = assetInfos;

    let index = -1;
    ais.map((v, i) => {
      if (assetInfo.id == v.id) {
        index = i;
      }
    })
    if (index == -1) {
      ais.push(assetInfo);
    } else {
      ais[index] = assetInfo;
    }
    setAssetInfos(ais);
  };

  const addAssetUploaders = () => {
    let assetInfo = {
      id: assetCount + 1, files: [], hdFiles: [], sdFiles: [], ldFiles: [], thumbnailFiles: [],
      chatbgFiles: [], assetName: "", assetID: "", assetType: "backgrounds",
      collectionType: "LEADERBOARD", uploadType: 0, textColor: [0, 0, 0],
      borderColor: [0, 0, 0], plistSuffix: 0,
    }

    setAssetCount(assetCount + 1)
    setAssetInfos(current => [...current, assetInfo])
    setActivePanel(assetCount + 1)
  }

  const getAssetAccordion = (assetInfo) => {
    const AD = styled((props) => (
      <Accordion disableGutters elevation={0} {...props} />
    ))(({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    }));

    const AS = styled((props) => (
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
      />
    ))(({ theme }) => ({
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)'
          : 'rgba(0, 0, 0, .03)',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
    }));

    return <AD expanded={activePanel == assetInfo.id}
      onChange={(e, exp) => {
        exp ? setActivePanel(assetInfo.id) : setActivePanel(0)
      }}>
      <AS aria-controls="panel1d-content">
        <Typography variant="h6">Asset {assetInfo.id}</Typography>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton aria-label="close" color="error" onClick={(e) => {
            setAssetInfos(assetInfos.filter(v => (v.id != assetInfo.id)))
          }}>
            <CloseIcon />
          </IconButton>
        </div>
      </AS>
      <AccordionDetails>
        <ClubCosmeticAssetUploader
          id={assetInfo.id}
          setAssetInfo={setAssetInfo}
          assetInfo={assetInfo}
          validating={validating}
          key={new Date().getTime()}
        />

      </AccordionDetails>
    </AD>
  }

  const validateAssets = () => {
    for (let i = 0; i < assetInfos.length; i++) {
      let ai = assetInfos[i]

      if (ai.assetID == '') {
        return false
      }

      if (ai.assetName == '') {
        return false
      }

      if (ai.assetType == "backgrounds" || ai.assetType == "entryEffects") {
        if (ai.uploadType == 0) {
          if (ai.files.length != 1 || ai.thumbnailFiles.length != 1) {
            return false
          }
        }

        if (ai.uploadType == 1) {
          if (ai.thumbnailFiles.length != 1 || ai.hdFiles.length != 3 ||
            ai.sdFiles.length != 3 || ai.ldFiles.length != 3) {
            return false
          }
        }
      }

      if (ai.assetType != "backgrounds" && ai.assetType != "entryEffects") {
        if (ai.files.length != 1) {
          return false
        }
      }
    }

    return true
  }

  const publishAsset = async () => {
    setValidating(true)
    if (!validateAssets()) {
      const key = enqueueSnackbar("Asset Publish Failed", {
        variant: "error",
      });
      return
    }

    setLoading(true);

    let ais = assetInfos

    for (let i = 0; i < ais.length; i++) {
      let ai = ais[i]
      for (let j = 0; j < ai.files.length; j++) {
        let file = ai.files[j]
        await uploadAsset(file, "0", ai)
      }

      for (let j = 0; j < ai.hdFiles.length; j++) {
        let file = ai.hdFiles[j]
        await uploadAsset(file, "1", ai)
      }

      for (let j = 0; j < ai.sdFiles.length; j++) {
        let file = ai.sdFiles[j]
        await uploadAsset(file, "2", ai)
      }

      for (let j = 0; j < ai.ldFiles.length; j++) {
        let file = ai.ldFiles[j]
        await uploadAsset(file, "3", ai)
      }

      for (let j = 0; j < ai.thumbnailFiles.length; j++) {
        let file = ai.thumbnailFiles[j]
        await uploadAsset(file, "4", ai)
      }

      for (let j = 0; j < ai.chatbgFiles.length; j++) {
        let file = ai.chatbgFiles[j]
        await uploadAsset(file, "5", ai)
      }

      ais[i] = ai
    }

    const payload = {
      assetInfos: ais,
    };
    let url = "/api/uploadCosmeticAsset/dev/" + currentServer;
    axios.post(url, payload, {})
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        const key = enqueueSnackbar("Asset Publish Successful", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Asset Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  }

  const uploadAsset = async (file, fileType, assetInfo) => {
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("assetID", assetInfo.assetID);
      data.append("assetName", assetInfo.assetName);
      data.append("assetType", assetInfo.assetType);
      data.append("uploadType", assetInfo.uploadType);
      data.append("collectionType", assetInfo.collectionType);
      data.append("fileUploadType", fileType);

      let url = "/api/file/cosmeticAssetID";
      const response = await axios.post(url, data);

      console.log(response)

      const jsonData = response.data;

      if (jsonData.plistSuffix) {
        assetInfo.plistSuffix = jsonData.plistSuffix
      }

      enqueueSnackbar("File - " + file.name + " uploaded successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("File - " + file.name + " not uploaded", {
        variant: "error",
      });
    }
  }

  const resetAssetUpload = () => {
    setAssetCount(0)
    setAssetInfos([])
    setValidating(false)
  }

  return (
    <div className={classes.root}>
      <Wrapper heading="Club Cosmetic Asset Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.header}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                size="large"
                onClick={addAssetUploaders}
              >
                Add More Asset
              </Button>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                size="large"
                onClick={resetAssetUpload}
                color="error"
              >
                Reset
              </Button>
            </FormControl>

            <div style={{ flexGrow: 1 }}></div>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="server-select"
                id="server-select"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={currentServer}
                onChange={handleServerChange}
              >
                <MenuItem value={0}>Dev</MenuItem>
                <MenuItem value={1}>Production</MenuItem>
              </Select>
            </FormControl>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              size="large"
              onClick={publishAsset}
              disabled={loading}
            >
              Publish
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <ul>
              <li>
                Before uploading, verify the name of the files.
              </li>
              <li>
                Upload multiple assets in single login otherwise your previously uploaded assets will be overwritten by newly uploaded assets.
              </li>
              <li>
                If you make a mistake, use the reset button to revert all changes.
              </li>
              <li>
                When you are completely sure about uploading the assets then only click on publish button to apply the changes on s3 and consul.
              </li>
            </ul>
          </Alert>
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          {assetUploaders.map((component) => (
            <>{component}</>
          ))}
        </Container>
      </Wrapper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        {loading && (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
    </div >
  );
};

ClubCosmeticAssetUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ClubCosmeticAssetUpload;
  