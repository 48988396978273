import React from "react";
import { withStyles } from "tss-react/mui";
import { Container, Typography, Button, TextField } from "@mui/material";
import { Component } from "react";
import { PropTypes } from "prop-types";
import { useSnackbar } from "notistack";
import Wrapper from "../components/Wrapper";
import axios from "axios";
import DropZone from "react-drop-zone";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
});

class UserRewards extends Component {
  state = {
    gems: 0,
    loading: false,
  };
  publishCSV = (file) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    this.setState({ loading: true });
    const data = new FormData();
    data.append("file", file);
    let url = "/api/reward/csv";
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        this.setState({ loading: false });
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        } else {
          this.props.updateUserRewards(file.name);
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
          persist: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
      });
  };

  handleGemsChange = (event) => {
    this.setState({
      gems: event.target.value,
    });
  };

  publishUserRewards = (file) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    this.setState({ loading: true });

    let url = "/api/reward/publish/gems/" + this.state.gems;
    axios
      .get(url)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        this.setState({ loading: false });
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed", {
            variant: "error",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        } else {
          this.props.updateUserRewards("");
          const key = enqueueSnackbar("Publish Success", {
            variant: "success",
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
          persist: true,
          onClick: () => {
            closeSnackbar(key);
          },
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Wrapper
          heading="User rewards"
          setIsLoggedIn={this.props.setIsLoggedIn}
        >
          <Container maxWidth="lg" className={classes.container}>
            <DropZone
              onDrop={(file, text) => {
                this.publishCSV(file);
              }}
            >
              {({ over, overDocument }) => (
                <Button color="primary" variant="contained">
                  Upload
                </Button>
              )}
            </DropZone>
            <br></br>
            <br></br>
            <TextField
              id="outlined-basic"
              type="number"
              label="Gems"
              value={this.state.gems}
              onChange={this.handleGemsChange}
              variant="outlined"
            />
            <br></br>
            <Typography variant="h2">
              {this.props.user.UserRewards.FileName}
            </Typography>
            <br></br>
            <br></br>
            <Button
              onClick={() => {
                this.publishUserRewards();
              }}
              color="primary"
              variant="contained"
              disabled={this.props.user.UserRewards.FileName == ""}
            >
              Publish
            </Button>
          </Container>
        </Wrapper>
      </div>
    );
  }
}

UserRewards.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(UserRewards, useStyles);
